import { USState } from '@/constructs/provinces/US/USState';
import { USTerritory } from '@/constructs/provinces/US/USTerritory';
import config from './config';

const states = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      // TODO: Move these US-specific states to a US-specific config.
      stateList: [
        {
          name: 'Alaska',
          id: USState.AK
        },
        {
          name: 'Alabama',
          id: USState.AL
        },

        {
          name: 'Arkansas',
          id: USState.AR
        },
        {
          name: 'Arizona',
          id: USState.AZ
        },
        {
          name: 'Colorado',
          id: USState.CO
        },
        {
          name: 'California',
          id: USState.CA
        },
        {
          name: 'District of Columbia',
          id: USState.DC
        },
        {
          name: 'Delaware',
          id: USState.DE
        },
        {
          name: 'Conneticut',
          id: USState.CT
        },
        {
          name: 'Georgia',
          id: USState.GA
        },

        {
          name: 'Florida',
          id: USState.FL
        },
        {
          name: 'Idaho',
          id: USState.ID
        },
        {
          name: 'Hawaii',
          id: USState.HI
        },
        {
          name: 'Illinois',
          id: USState.IL
        },
        {
          name: 'Indiana',
          id: USState.IN
        },
        {
          name: 'Kansas',
          id: USState.KS
        },
        {
          name: 'Iowa',
          id: USState.IA
        },
        {
          name: 'Louisianna',
          id: USState.LA
        },
        {
          name: 'Kentucky',
          id: USState.KY
        },
        {
          name: 'Maryland',
          id: USState.MD
        },
        {
          name: 'Maine',
          id: USState.ME
        },
        {
          name: 'Michigan',
          id: USState.MI
        },

        {
          name: 'Massachusetts',
          id: USState.MA
        },
        {
          name: 'Mississippi',
          id: USState.MS
        },
        {
          name: 'Minnesota',
          id: USState.MN
        },
        {
          name: 'Montana',
          id: USState.MT
        },
        {
          name: 'Missouri',
          id: USState.MO
        },
        {
          name: 'Nevada',
          id: USState.NV
        },
        {
          name: 'Nebraska',
          id: USState.NE
        },
        {
          name: 'New Jersey',
          id: USState.NJ
        },
        {
          name: 'New Hampshire',
          id: USState.NH
        },
        {
          name: 'New York',
          id: USState.NY
        },
        {
          name: 'New Mexico',
          id: USState.NM
        },
        {
          name: 'North Dakota',
          id: USState.ND
        },
        {
          name: 'North Carolina',
          id: USState.NC
        },

        {
          name: 'Ohio',
          id: USState.OH
        },
        {
          name: 'Oklahoma',
          id: USState.OK
        },
        {
          name: 'Oregon',
          id: USState.OR
        },
        {
          name: 'Pennsylvania',
          id: USState.PA
        },

        {
          name: 'South Carolina',
          id: USState.SC
        },
        {
          name: 'Rhode Island',
          id: USState.RI
        },
        {
          name: 'Tennesse',
          id: USState.TN
        },
        {
          name: 'South Dakota',
          id: USState.SD
        },
        {
          name: 'Utah',
          id: USState.UT
        },
        {
          name: 'Texas',
          id: USState.TX
        },
        {
          name: 'Virginia',
          id: USState.VA
        },
        {
          name: 'Vermont',
          id: USState.VT
        },
        {
          name: 'West Virginia',
          id: USState.WV
        },
        {
          name: 'Washington',
          id: USState.WA
        },
        {
          name: 'Wyoming',
          id: USState.WY
        },
        {
          name: 'Wisconsin',
          id: USState.WI
        }
      ],

      territoryList: [
        {
          name: 'American Samoa',
          id: USTerritory.AS
        },
        {
          name: 'Guam',
          id: USTerritory.GU
        },
        {
          name: 'Federated States Of Micronesia',
          id: USTerritory.FM
        },
        {
          name: 'Marshall Islands',
          id: USTerritory.MH
        },
        {
          name: 'Northern Mariana Islands',
          id: USTerritory.MP
        },
        {
          name: 'Palau',
          id: USTerritory.PW
        },
        {
          name: 'Puerto Rico',
          id: USTerritory.PR
        },
        {
          name: 'Virgin Islands',
          id: USTerritory.VI
        }
      ]
    }
  }
});

export default states;
