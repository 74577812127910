import config from './config';
import { FacetAppearance } from './types/FacetAppearance';

const productList = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Refinements (filters, sorting) are by default shown
       * in drawer on mobile, and on top of PLP on desktop.
       *
       * Set this to `true` to also use the drawer on desktop.
       */
      refinementsAlwaysInDrawer: false,
      facets: {
        color: {
          appearance: FacetAppearance.Swatches,
          titleStringPath: 'Color'
        },
        size: {
          appearance: FacetAppearance.ToggleButtons,
          titleStringPath: 'Size',
          hintStringPath: 'facets.size.hint'
        },
        'product-type': {
          appearance: FacetAppearance.Checkboxes,
          titleStringPath: 'Product Type'
        }
      },

      searchPath: '/q',

      productsPerPage: 12,

      contentTiles: {
        /** Margin is number of products before it tries to insert the first content tile. */
        margin: 4,
        /**
         * Frequency is number of products before it tries to insert
         * a content tile after the first one.
         */
        frequency: 4
      },
      sortBy: 'relevancy'
    }
  }
});

export default productList;
