import config from './config';

const studentDiscount = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Display value for the student discount link. This value is used in the breadcrumbs of the page.
       * */
      displayValue: 'Sanuk Student Discount',
      /**
       * This is used to navigate to the student discount page in the breadcrumbs of the page.
       */
      link: '/sanuk-student-discount',
      /**
       * The URL to the embedded student discount page.
       */
      embedUrl: 'https://sanuk-embedded.myunidays.com/%22'
    }
  },
  'TEVA-US': {
    default: {
      displayValue: 'Teva Student Discount',
      link: '/teva-student-discount',
      embedUrl: 'https://teva-embedded.myunidays.com/'
    }
  }
});

export default studentDiscount;
