import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '../error-utils';

export const { LikelyMisconfigurationError } = errorFactory(
  'LikelyMisconfigurationError'
);

/** An error which was most likely caused by a misconfiguration. */
export type LikelyMisconfigurationError = InstanceTypeOf<
  typeof LikelyMisconfigurationError
>;
