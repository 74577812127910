import type { DTO, JSONValue } from '@/type-utils';
import { InvalidStateError } from '@/utils/errors';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import { isNullish } from '@/utils/null-utils';
import { isObject, toStatelessImmutable } from '@/utils/object-utils';
import {
  Environment,
  EnvironmentService
} from '../../isomorphic/EnvironmentService';
import Model, { DTOOfModel, ModelConstructor } from '../Model';
import { ConfigProxy } from './ConfigProxy';
import type IConfig from './IConfig';

/**
 * `ConfigModel` represents a given value in our configuration files found in:
 * `@/configs/`. It may also be used to represent a "namespace" within those configs.
 * A namespace is just an group of primitive config values or further nested namespaces.
 *
 * The `.value` property can be used to obtain the dereferenced value of this config.
 * When the config value is a namespace, the value will be a `ConfigModel` itself.
 * To alleviate needing to write `configVal.value.nestedVal.value.furtherNestedVal.value`,
 * when working with deep hierarchies, you can conveniently just write
 * `configVal.nestedVal.furtherNestedVal.value` instead. However, when the property name
 * would conflict with a property normally defined on `ConfigModel` (like `.key`), the
 * constructor will throw an error. For this reason, we should avoid using identifiers such
 * as these in our config files.
 * @borrows Model#from as ConfigModel#from
 */
export default class ConfigModel<T>
  extends Model<DTO<IConfig<T>>>
  implements IConfig<T>
{
  /** @inheritDoc */
  public readonly key: string;

  private _value?: T;

  /** @inheritDoc */
  public get value(): T {
    // eslint-disable-next-line default-case -- The "default" case and case when values are missing are handled in the 'if' after the switch.
    switch ((process.env.NEXT_PUBLIC_APP_ENV)) {
      case Environment.Production:
        if (this._prodValue !== undefined) return this._prodValue;
        break;
      case Environment.Development:
        if (this._devValue !== undefined) return this._devValue;
        break;
      case Environment.UAT:
        if (this._uatValue !== undefined) return this._uatValue;
        break;
      case Environment.QA:
        if (this._qaValue !== undefined) return this._qaValue;
        break;
    }
    if (this._value !== undefined) return this._value;

    throw new InvalidStateError(
      `\`ConfigModel\` for key "${this.key}" does not appear to have any values configured.`
    );
  }

  private _qaValue?: T;

  /** @inheritDoc */
  public get qaValue(): T {
    // Either use the backing field specific for this environment,
    // or fallback to the default.
    if (this._qaValue !== undefined) return this._qaValue;
    return this.value;
  }

  private _uatValue?: T;

  /** @inheritDoc */
  public get uatValue(): T {
    // Either use the backing field specific for this environment,
    // or fallback to the default.
    if (this._uatValue !== undefined) return this._uatValue;
    return this.value;
  }

  private _devValue?: T;

  /** @inheritDoc */
  public get devValue(): T {
    // Either use the backing field specific for this environment,
    // or fallback to the default.
    if (this._devValue !== undefined) return this._devValue;
    return this.value;
  }

  private _prodValue?: T;

  /** @inheritDoc */
  public get prodValue(): T {
    // Either use the backing field specific for this environment,
    // or fallback to the default.
    if (this._prodValue !== undefined) return this._prodValue;
    return this.value;
  }

  /** @inheritDoc */
  public constructor(config: IConfig<T>);

  /** @inheritDoc */
  public constructor(config: Partial<DTO<IConfig<T>>>);

  /** @inheritDoc */
  public constructor(config: Partial<DTO<IConfig<T>>> | IConfig<T>) {
    super(config as DTO<IConfig<T>>);

    if (!('key' in config)) {
      throw new InvalidArgumentError(
        `Property "key" was not found in the config-like object passed to the \`ConfigModel\` constructor. Configs must have a key.\n
The constructor was passed: ${
          typeof config === 'object'
            ? JSON.stringify(config, null, 2)
            : String(config)
        }`
      );
    }

    this.key = config.key!;

    // Set the backing fields to whatever is available.
    if ('value' in config) this._value = config.value;
    if ('qaValue' in config) this._qaValue = config.qaValue;
    if ('uatValue' in config) this._uatValue = config.uatValue;
    if ('devValue' in config) this._devValue = config.devValue;
    if ('prodValue' in config) this._prodValue = config.prodValue;

    // Get the keys of this object prior to adding new keys.
    const reservedKeyNames = Object.keys(this).map((key) => `"${key}"`);

    // Add any keys from the underlying config object. Throw if there is a conflict.
    if (typeof this.value === 'object' && !isNullish(this.value)) {
      for (const key of Object.keys(this.value)) {
        if (key in this) {
          throw new Error(
            `A config model was passed a value object whose property name "${key}" ` +
              `is already a reserved key name in \`ConfigModel\`.\n` +
              `Reserved keys are ${reservedKeyNames.join(', ')}.\n` +
              `The value object was: \n` +
              `${JSON.stringify(this.value, null, 2)}`
          );
        }
        (this as Record<string, unknown>)[key] = (
          this.value as Record<string, unknown>
        )[key];
      }
    }
  }

  /** @inheritDoc */
  public static override from<
    T,
    This extends ModelConstructor<DTO<any>> = typeof this
  >(
    this: This,
    dto: InstanceType<This> | DTOOfModel<InstanceType<This>>
  ): InstanceType<This> & ConfigProxy<T> {
    return super.from(dto) as InstanceType<This> as InstanceType<This> &
      ConfigProxy<T>;
  }

  /** @inheritDoc */
  public override toDTO(): DTO<IConfig<T>> {
    const { key, value, qaValue, uatValue, devValue, prodValue } = this;
    return toStatelessImmutable<IConfig<T>>({
      key,
      value,
      qaValue,
      uatValue,
      devValue,
      prodValue
    }) as DTO<IConfig<T>>;
  }

  /**
   * Called implicitly in `JSON.stringify()`. This will serialize the underlying
   * value rather than the config model itself. As usual, when there is a need to
   * serialize the config model itself, first call `.toDTO()`.
   * @returns An object or value to be serialized.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#tojson_behavior
   * @example ```ts
   * // Serialize the underlying config value to a JSON string:
   * JSON.stringify(configModel);
   * // Or...
   * JSON.stringify(configModel.value);
   *
   * // Serialize the config model itself:
   * JSON.stringify(configModel.toDTO());
   * ```
   */
  public override toJSON(): JSONValue<true> {
    const { value } = this;

    // Check if the config is an object. If so, it may contain other config
    // models nested within itself.
    if (isObject(value)) {
      const serializedEntries = Object.entries(value).map(([key, val]) => {
        // If an instance of ConfigModel is found as a value, call toJSON on it
        // as well to recursively serialize up until it reaches a primitive.
        if (val instanceof ConfigModel) {
          return [key, val.toJSON()];
        }

        return [key, val];
      });

      return Object.fromEntries(serializedEntries);
    }

    // We return the underlying value for this config model to be serialized.
    return this.value as JSONValue<true>;
  }
}
