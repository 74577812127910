import config from './config';

const checkoutTimeout = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Expired checkout timeout after user inactivity (30 min). */
      timeout: 1000 * 60 * 30,
      /** Amount of time before an idle action will be called (1 min). */
      promptTimeout: 1000 * 60
    }
  }
});

export default checkoutTimeout;
