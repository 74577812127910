// Represents all methods on a service mock or any properties which normally provides
// a function as its value. The mocked function is returned by a function accepting

import type Service from '../../Service';
import { Constructor, JSONValue } from '@/type-utils';
import { MockState } from './MockState';

/**
 * Service Mock is a base class for service mocks to inherit from. It specifies that a
 * service can be mocked and dictates what mocking for that services looks like.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- For backwards compatibility.
export abstract class ServiceMock<
  T extends Service,
  U extends Record<never, JSONValue> = any
> {
  /**
   * Can be initialized in the `initializeMockedMembers` method.
   */
  protected abstract _state: MockState<U>;

  /** The Current state object. */
  public abstract get state(): MockState<U>;

  /**
   * Gets the current mock of the ServiceMock.
   */
  public abstract getMock(): T;

  /**
   * Called by the constructor to setup the initial mock.
   * @param service - The service that is being mocked.
   */
  protected abstract initializeMockedMembers(service: T | Constructor<T>): void;
}
