import {
  AVATAX_COMPANY_CODE,
  AVATAX_PASSWORD,
  AVATAX_USERNAME
} from '../env/server';

import config from '../config';

/** AvaTax configuration. */
const avaTax = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      baseURL: {
        dev: 'https://sandbox-rest.avatax.com/api/v2',
        qa: 'https://sandbox-rest.avatax.com/api/v2',
        uat: 'https://sandbox-rest.avatax.com/api/v2',
        prod: 'https://rest.avatax.com/api/v2'
      },
      username: AVATAX_USERNAME ?? '',
      password: AVATAX_PASSWORD ?? '',
      companyCode: AVATAX_COMPANY_CODE ?? '',

      /**
       * This is the default tax code for shipping line items.
       * @see {@link https://taxcode.avatax.avalara.com/search?category=Freight&tab=decision_tree Avalara Tax Codes Search}
       */
      shippingTaxCode: 'FR020800', // "Shipping charges that exceed the actual cost of delivery"

      /** The address that product is shipped from - the warehouse. */
      shipFromAddress: {
        line1: '2000 Main Street',
        city: 'Irvine',
        region: 'CA',
        country: 'US',
        postalCode: '92614'
      }
    }
  },
  'AHNU-US': {
    default: {
      shipFromAddress: {
        line1: '17791 N. Perris Blvd.',
        city: 'Moreno Valley',
        region: 'CA',
        country: 'US',
        postalCode: '92551'
      }
    }
  }
});

export default avaTax;
