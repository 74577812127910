/**
 * Represents a ISO 639-1 language identifier.
 * @see https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
export enum Language {
  /**
   * Represents the English language as an ISO 639-1 language identifier.
   */
  EN = 'en',

  /**
   * Represents the Spanish language as an ISO 639-1 language identifier.
   */
  ES = 'es',

  /**
   * Represents the French language as an ISO 639-1 language identifier.
   */
  FR = 'fr',

  /**
   * Represents the German language as an ISO 639-1 language identifier.
   */
  DE = 'de',

  /**
   * Represents the Italian language as an ISO 639-1 language identifier.
   */
  IT = 'it',

  /**
   * Represents the Japanese language as an ISO 639-1 language identifier.
   */
  JP = 'jp',

  /**
   * Represents the Dutch language as an ISO 639-1 language identifier.
   */
  NL = 'nl'
}
