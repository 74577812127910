import config from './config';

const featureFlagging = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      featureFlagDefaults: {
        /** A feature flag for use in tests. */
        __reservedForTesting: { enabled: true }
      }
    }
  }
});

export default featureFlagging;
