import {
  COREMEDIA_ACCESS_TOKEN,
  COREMEDIA_PREVIEW_ACCESS_TOKEN
} from '../env/public';

import config from '../config';

const SANUK_CM = {
  default: {
    siteId: 'edb33ace18cae409fd5d2bca9a136393',
    segments: {
      /** Root CM query. */
      homepage: 'sanuk-hd'
    }
  }
} as const;

const AHNU_CM = {
  default: {
    siteId: 'bf59b0bdf1d3911062e8519fab1e5554',
    segments: {
      /** Root CM query. */
      homepage: 'ahnu-hd'
    }
  }
} as const;

const HOKA_CM = {
  default: {
    siteId: '219e767b261e9c860e988aeec613cf07',
    segments: {
      /** Root CM query. */
      homepage: 'hoka-hd'
    }
  }
} as const;

const KOOLABURRA_CM = {
  default: {
    siteId: '765a13d0a3341089682237b87869b1df',
    segments: {
      /** Root CM query. */
      homepage: 'koolaburra-hd'
    }
  }
} as const;

const TEVA_CM = {
  default: {
    siteId: 'dd6a48260b7d218edcd28fc08dc9dd2e',
    segments: {
      /** Root CM query. */
      homepage: 'teva-hd'
    }
  }
} as const;

const UGG_CM = {
  default: {
    siteId: '8c4792c32955c1f08487b6348c051598',
    segments: {
      /** Root CM query. */
      homepage: 'ugg-hd'
    }
  }
} as const;

/** Configuration for the CoreMedia integration service. */
const coremedia = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Is Coremedia currently enabled. */
      enabled: true,
      /** The base URL used when preview is false. */
      baseUrl: 'https://caas.production.deckers.coremedia.cloud',
      /** The base url with the preview on. */
      previewBaseUrl: 'https://caas-preview.production.deckers.coremedia.cloud',
      /** The main access token. */
      accessToken: `${COREMEDIA_ACCESS_TOKEN}` as string,
      /** The preview access token. */
      previewAccessToken: `${COREMEDIA_PREVIEW_ACCESS_TOKEN}` as string,
      siteId: undefined as unknown as string, // should be overridden by site
      segments: {
        /** Root CM query. */
        homepage: '', // should be overridden by site
        /** This is the navigation page. It holds the placement for the navigation. */
        navigation: 'navigation',
        /**
         * The page slug for getting content of the global page. This
         * includes content for the header and footer banners and navigation
         * content.
         */
        global: 'global',
        /** The footer page slug. */
        footer: 'footer'
      }
    }
  },
  'AHNU-US': { ...AHNU_CM },
  'HOKA-US': { ...HOKA_CM },
  'HOKA-CA': { ...HOKA_CM },
  'HOKA-UK': { ...HOKA_CM },
  'TEVA-US': { ...TEVA_CM },
  'UGG-US': { ...UGG_CM },
  'UGG-CA': { ...UGG_CM },
  'UGG-UK': { ...UGG_CM },
  'UGG-COLLAB-US': { ...UGG_CM },
  'KOOLABURRA-US': { ...KOOLABURRA_CM },
  'SANUK-US': { ...SANUK_CM },
  'SANUK-UK': { ...SANUK_CM },
  'CONDUCTOR-US': { ...AHNU_CM }
});

export default coremedia;
