import config from '../config';
import MediaSize from '../types/ConfiguredMediaSize';

const cloudinary = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      baseURL: 'https://dms.deckers.com',

      /**
       * **From the docs:**
       * The name of your Cloudinary account, a unique public identifier for URL building and API access.
       */
      cloudName: 'sanuk',

      /**
       * Include supported transformations here. For now, only `qualifier` is required; but we could add
       * other features such as `supportedTypes` to specify whether a transformation is supported on
       * either images and/or videos.
       *
       * @see https://cloudinary.com/documentation/transformation_reference
       */
      transformations: {
        width: {
          qualifier: 'w'
        },

        height: {
          qualifier: 'h'
        },

        crop: {
          qualifier: 'c'
        },

        quality: {
          qualifier: 'q'
        },

        dpr: {
          qualifier: 'dpr'
        },

        format: {
          qualifier: 'f'
        },

        gravity: {
          qualifier: 'g'
        },

        namedTransformation: {
          qualifier: 't'
        },

        aspectRatio: {
          qualifier: 'ar'
        }
      },

      configuredSizeTransformations: {
        [MediaSize.ExtraSmall]: 'extra_small',
        [MediaSize.Small]: 'small',
        [MediaSize.PDPSliderThumbLarge]: 'pdp_slider_thumb_large',
        [MediaSize.PDPSliderThumbLarge2x]: 'pdp_slider_thumb_large2x',
        [MediaSize.LifestyleThumbLarge]: 'lifestyle_thumb_large',
        [MediaSize.LifestyleThumbLarge2x]: 'lifestyle_thumb_large2x',
        [MediaSize.LifestyleDISSmall]: 'lifestyle_dis_small',
        [MediaSize.LifestyleDISLarge]: 'lifestyle_dis_large',
        [MediaSize.PDPSliderSmall]: 'pdp_slider_small',
        [MediaSize.PDPSliderLarge]: 'pdp_slider_large',
        [MediaSize.WishlistTitle]: 'wishlist_title'
      }
    }
  },
  'AHNU-US': {
    default: {
      cloudName: 'ahnu'
    }
  },
  'TEVA-US': {
    default: {
      cloudName: 'teva'
    }
  },
  'HOKA-US': {
    default: {
      cloudName: 'hoka'
    }
  },
  'UGG-US': {
    default: {
      cloudName: 'ugg'
    }
  },
  'KOOLABURRA-US': {
    default: {
      cloudName: 'koolaburra'
    }
  }
});

export default cloudinary;
