import { computed, makeObservable, observable, runInAction } from 'mobx';
import { isPromiseLike, promiseTry } from './async-utils';
import { InvalidArgumentError } from './errors';

/**
 * `StaleWhileRevalidate` is used to represent a value which we can get immediately,
 * but may require an update. It can be used just like a promise with `.then` or `await`.
 * The `value` property may represent the initial "stale" value or the "fresh" value
 * that was retrieved. The `value` property is observable and can be referenced in
 * React components directly.
 *
 * Note that because `StaleWhileRevalidate` is a promise-like object, returning it
 * from an async function will cause it to be awaited / "unwrapped" automatically.
 * In other words, `Promise<StaleWhileRevalidate<T>>` behaves the same as `Promise<T>`.
 *
 * @example
 * function MyComponent({ swr }) {
 *  if (swr.pending) {
 *    return <div>Loading...</div>;
 *  }
 *
 *  if (swr.errored) {
 *    return <div>Error: {swr.error}</div>;
 *  }
 *
 *  return <div>Value: {swr.value}</div>;
 * }
 *
 * @example
 * const swr = new StaleWhileRevalidate('stale', Promise.resolve('fresh'));
 * await swr; // 'fresh'
 */
class StaleWhileRevalidate<T> implements PromiseLike<T> {
  @observable private _value: T;
  @observable private _pending: boolean = false;

  @observable private _errored: boolean = false;
  @observable private _error: unknown;

  private _promise: Promise<T>;

  /** @returns The current value. May be the "stale" or "fresh" value. */
  @computed public get value(): T {
    return this._value;
  }

  /** @returns Whether the async operation to fetch the "fresh" data has settled. */
  @computed public get pending(): boolean {
    return this._pending;
  }

  /** @returns Whether the async operation to fetch the "fresh" data has failed. */
  @computed public get errored(): boolean {
    return this._errored;
  }

  /** @returns The error value that was thrown when fetching the "fresh" data. */
  @computed public get error(): unknown {
    return this._error;
  }

  /**
   * Creates a `StaleWhileRevalidate` that immediately resolves
   * to the "stale" value and never enters a "pending" state.
   * @param staleValue - The "stale" value.
   * @inheritdoc
   */
  public constructor(staleValue: T);

  /**
   * @param staleValue - The "stale" value.
   * @param futureValue - A promise which returns a promise
   * which resolves to the "fresh" updated value.
   * @inheritdoc
   */
  public constructor(staleValue: T, futureValue?: PromiseLike<T>);

  /**
   * @param staleValue - The "stale" value.
   * @param futureValue - A function which returns a promise
   * which resolves to the "fresh" updated value.
   * @inheritdoc
   */
  public constructor(staleValue: T, futureValue?: () => PromiseLike<T>);

  /**
   * @param staleValue - The "stale" value.
   * @param [futureValue] - Either a promise or a function that returns a promise
   * which resolves to the "fresh" updated value.
   * @inheritdoc
   */
  public constructor(
    staleValue: T,
    futureValue?: (() => PromiseLike<T>) | PromiseLike<T>
  ) {
    if (isPromiseLike(staleValue)) {
      /**
       * `StaleWhileRevalidate` cannot wrap other promise-like objects because, during resolution,
       * the `futureValue` promise will treat the "fresh" promise-like as a step in the chain,
       * rather than the final value. This would result in a mismatch between the type of `value`
       * initially, and the type of `value` after resolution.
       *
       * Ideally we would restrict the generic to exclude promise-like objects, but this is not
       * currently possible in TypeScript. As a result, we must throw an error at runtime if a
       * promise-like object is passed as the "stale" value.
       */
      throw new InvalidArgumentError(
        'The stale value for StaleWhileRevalidate cannot be promise-like.'
      );
    }

    this._value = staleValue;

    // handle case where no future value is provided
    if (!futureValue) {
      this._promise = Promise.resolve(staleValue);
      makeObservable(this);
      return;
    }

    // Extract the promise for the future value.
    this._promise = this.connectPromise(
      // Extract the promise for the future value.
      typeof futureValue === 'function' ? promiseTry(futureValue) : futureValue
    );

    makeObservable(this);
  }

  /** @inheritdoc */
  public async then<TResult1 = T, TResult2 = never>(
    onfulfilled?:
      | ((value: T) => TResult1 | PromiseLike<TResult1>)
      | null
      | undefined,
    onrejected?:
      | ((reason: any) => TResult2 | PromiseLike<TResult2>)
      | null
      | undefined
  ): Promise<TResult1 | TResult2> {
    return this._promise.then(onfulfilled, onrejected);
  }

  /** @inheritdoc */
  public async catch<TResult = never>(
    onrejected?:
      | ((reason: any) => TResult | PromiseLike<TResult>)
      | null
      | undefined
  ): Promise<T | TResult> {
    return this._promise.catch(onrejected);
  }

  /** @inheritdoc */
  public async finally(
    onfinally?: (() => void) | null | undefined
  ): Promise<T> {
    return this._promise.finally(onfinally);
  }

  /**
   * Connects the given promise-like object to this `StaleWhileRevalidate`, and returns
   * the promise.
   *
   * Note: the return value should be assigned to the {@link _promise} property.
   *
   * @param promiseLike - A promise-like object to connect to the `StaleWhileRevalidate`.
   * @returns The promise that was connected.
   * @example
   * this._promise = this.connectPromise(Promise.resolve('test'));
   */
  private async connectPromise(promiseLike: PromiseLike<T>): Promise<T> {
    this._pending = true;

    // eslint-disable-next-line promise/catch-or-return -- the callbacks handle the result
    promiseLike.then(
      (value) =>
        runInAction(() => {
          this._value = value;
          this._pending = false;
        }),
      // we must use the `onrejected` callback rather than `catch` to ensure
      // that it runs in the earliest microtask
      (err) =>
        runInAction(() => {
          this._pending = false;
          this._errored = true;
          this._error = err;
        })
    );

    return promiseLike;
  }

  /**
   * Creates a `StaleWhileRevalidate` that is always pending and never settles.
   *
   * **DO NOT** call `await` on this or it will hang forever and potentially create
   * a memory leak. Instead, only access the value through the `.value` property.
   *
   * @param value - The value to create a `StaleWhileRevalidate` from.
   * @returns A `StaleWhileRevalidate` that is always pending and never settles.
   */
  public static forever<T>(value: T): StaleWhileRevalidate<T> {
    return new StaleWhileRevalidate(value, new Promise(() => {}));
  }
}

export default StaleWhileRevalidate;
