import config from './config';

/** Contains configs related to shipping methods. */
const inventory = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * If purchasing products with preorder stock is allowed.
       *
       * Disabled for MVP.
       */
      allowPreorder: false,

      /**
       * If purchasing products with backorder stock is allowed.
       *
       * Disabled for MVP.
       */
      allowBackorder: false,

      /**
       * The TTL (in seconds) to use for the cache in the isomorphic
       * `InventoryService`.
       */
      isomorphicInventoryCacheTTL: 300, // 5 minutes

      /**
       * The TTL (in seconds) to use for the **stale** cache in the isomorphic
       * `InventoryService`.
       *
       * The stale cache here refers to a secondary, longer-lived cache used to
       * store inventory data that is known to be stale without disrupting the
       * primary cache.
       */
      isomorphicStaleInventoryCacheTTL: 1800, // 30 minutes,

      /**
       * The TTL (in seconds) to use for the cache in the
       * `ServerInventoryService`.
       *
       * It is recommended to use a very short cache since the server is always
       * expected to provide fresh inventory data. The purpose of the cache is
       * only to prevent multiple successive calls from making multiple requests
       * to AWS.
       */
      serverInventoryCacheTTL: 15
    }
  }
});

export default inventory;
