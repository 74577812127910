import ShippingMethod from '@/constructs/ShippingMethod';
import config from './config';

/** Contains configs related to shipping methods. */
const shippingMethods = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Contains configs related to caching. */
      caching: {
        /**
         * The TTL for the cache used in the isomorphic ShippingMethodService.
         */
        isomorphicCacheTTL: 30,

        /**
         * The TTL for the cache used in the ServerShippingMethodService.
         */
        serverCacheTTL: 30
      },

      /**
       * Contains overrides for specific attributes of shipping methods.
       *
       * **NOTE: ** Temporary! AWS is not yet providing us prices nor delivery
       * date estimates. These overrides are meant to complement the data we are
       * receiving from AWS, and will be removed once said data comes complete.
       */
      overrides: {
        /**
         * Price overrides. Value should be a number with the amount to
         * charge in the current locale's currency.
         */
        prices: {
          [ShippingMethod.STG]: 8,
          [ShippingMethod.STG2]: 8,
          [ShippingMethod.ST2]: 20,
          [ShippingMethod.STO]: 30,
          [ShippingMethod.USPSGround]: 0,
          [ShippingMethod.SFSIntl]: 75
        },

        /**
         * Delivery business day overrides. Value should be an array with two
         * values: earliest and latest delivery date in business days.
         *
         * @example
         * `[ShippingMethod.STG]: [3, 6]`
         *
         * Orders shipped with the STG shipping method will arrive in 3 to 6
         * business days.
         */
        deliveryBusinessDayRanges: {
          [ShippingMethod.STG]: [3, 6],
          [ShippingMethod.STG2]: [3, 6],
          [ShippingMethod.ST2]: [2, 3],
          [ShippingMethod.STO]: [1, 2]
        }
      }
    }
  }
});

export default shippingMethods;
