import { YOTPO_API_KEY, YOTPO_API_SECRET } from '../env/server';
import config from '../config';

const yotpo = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** API URL of the third-party reviews service provider. */
      apiURL: 'https://api.yotpo.com',
      /** API key for the Yotpo service. */
      apiKey: YOTPO_API_KEY,
      /** API secret for the Yotpo service. */
      apiSecret: YOTPO_API_SECRET
    }
  }
});

export default yotpo;
