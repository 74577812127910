import type { Nullable } from '@/type-utils';
import { errorToDTO } from '@/utils/error-utils';
import Service from '../../Service';
import { EnvironmentService } from '../EnvironmentService';

import CurrentRequestService from '../CurrentRequestService';
import { LogLevel } from './LogLevel';
import LoggerServiceMock from './LoggerServiceMock';

/** Defines valid log message types. */
export type Loggable = string | Error;

/** Abstracts logging for the project. */
export class LoggerService extends Service {
  /**
   * Gets a reference request ID for logs occurring on the server
   * and within a request.
   *
   * @returns
   * - The ID retrieved from the request this log originated on if available.
   * - `(Unable to get request ID)` if called within a request, but the request
   * is not accessible.
   * - `null` if called outside of a request.
   */
  private getRequestID(): Nullable<string> {
    if ((typeof window === "undefined") && CurrentRequestService.inRequest) {
      const [req, res] = CurrentRequestService.tryGet();
      const id = req?.id ?? '(Unable to get request ID)';

      return id;
    }
    return null;
  }

  /**
   * Log a message and optionally specify log level.
   * @param message - The message string or exception to be logged.
   * @param [level=info] - Level of this log.
   */
  public log(message: Loggable, level: LogLevel = LogLevel.Info): void {
    /** The object that will be output into the logs containing the message and metadata. */
    const logObject = {
      ...// If the "message" is an error, convert it to a DTO of the Error instance.
      (message instanceof Error ? { error: errorToDTO(message) } : { message }),
      level,
      timestamp: new Date().toISOString(),
      requestID: this.getRequestID() ?? undefined // Omit, if there is no request ID.
    };

    /** The log object serialized to a JSON string and pretty-printed. */
    const logObjectJSON = JSON.stringify(logObject, null, 2);

    // Log the message at the specified level.
    switch (level) {
      case LogLevel.Error:
        console.error(logObjectJSON);
        break;
      case LogLevel.Warn:
        console.warn(logObjectJSON);
        break;
      case LogLevel.Info:
        console.info(logObjectJSON);
        break;
      case LogLevel.Debug:
        console.debug(logObjectJSON);
        break;
    }
  }

  /**
   * Logs a message with an "error" log level.
   * @param message - The message string or exception to be logged.
   */
  public error(message: Loggable): void {
    this.log(message, LogLevel.Error);
  }

  /**
   * Logs a message with a "warn" log level.
   * @param message - The message string or exception to be logged.
   */
  public warn(message: Loggable): void {
    this.log(message, LogLevel.Warn);
  }

  /**
   * Logs a message with an "info" log level.
   * @param message - The message string or exception to be logged.
   */
  public info(message: Loggable): void {
    this.log(message, LogLevel.Info);
  }

  /**
   * Logs a message with a "debug" log level.
   * @param message - The message string or exception to be logged.
   */
  public debug(message: Loggable): void {
    this.log(message, LogLevel.Debug);
  }
}

export default LoggerService.withMock(
  new LoggerServiceMock(LoggerService)
) as unknown as LoggerService;
