import config from '../config';
import { FacetAppearance } from '../types/FacetAppearance';

const coveo = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * This is the API key that Coveo services use to authenticate requests.
       * It is a public API key with very limited privileges, meaning
       * that it can be exposed with no security risk.
       *
       * However, this should be changed in the future due to analytics
       * logging issues that result from using this authentication method.
       *
       * @see [Public API Key Authentication Scenario](https://docs.coveo.com/en/1369/build-a-search-ui/choose-and-implement-a-search-authentication-method#public-api-key-authentication-scenario)
       */
      publicApiKey: 'xx9a08cb02-81d6-46cf-ab78-75d208d45174',

      /**
       * The base url used for the search API.
       */
      baseSearchUrl: 'https://platform.cloud.coveo.com/rest/search/v2',

      /**
       * The base url used for the analytics API. It can have one of two endpoint addtions
       * click and search.
       * @see https://docs.coveo.com/en/1502/build-a-search-ui/log-search-events
       */
      baseAnalyticsUrl:
        'https://analytics.cloud.coveo.com/rest/ua/v15/analytics',

      /**
       * Unique identifier for the [Coveo Organization](https://docs.coveo.com/en/185/glossary/coveo-organization)
       * to use.
       *
       * A Coveo Organization is a single tenant instance that hosts
       * data and Coveo service configurations.
       */
      orgId: 'deckersoutdoorcorporationnonproduction1wo0g0rc3',

      /**
       * The Coveo [Search Hub](https://docs.coveo.com/en/1342/glossary/search-hub)
       * to use.
       *
       * A Search Hub is a query parameter whose value is a descriptive
       * name of the search interface from which the query originates.
       * This allow us to create an optimized query pipeline for each
       * specific search interface.
       */
      searchHub: 'search-sanuk-us-en',

      /** The default [number of results](https://docs.coveo.com/en/1461/build-a-search-ui/query-parameters#RestQueryParameters-numberOfResults) to use for list queries. */
      defaultLimit: 5000,

      /** The default [sort criteria](https://docs.coveo.com/en/1461/build-a-search-ui/query-parameters#RestQueryParameters-sortCriteria) to use for list queries. */
      defaultSortCriteria: 'relevancy',

      /**
       * The default [facet requests](https://docs.coveo.com/en/1461/build-a-search-ui/query-parameters#RestQueryParameters-facets) to include in list queries.
       * @see [Facet Request Schema](https://docs.coveo.com/en/1461/build-a-search-ui/query-parameters#RestQueryParameters-facets)
       */
      defaultFacetRequests: {
        0: {
          facetId: '@headless_gender',
          field: 'headless_gender'
        },
        1: {
          facetId: '@headless_color_bucket',
          field: 'headless_color_bucket'
        },
        2: {
          facetId: '@headless_retail_price',
          field: 'headless_retail_price',
          type: 'numericalRange'
        },
        3: {
          facetId: '@headless_category',
          field: 'headless_category',
          type: 'hierarchical'
        }
      },

      /**
       * Helper map that supplies necessary data to transform {@link ICoveoResponseFacet}
       * objects to first-party {@link IProductListFilter} objects.
       *
       * @see {@link ProductListService.responseFacetToFilter}
       */
      facetsToFiltersMap: {
        '@headless_gender': {
          id: 'gender',
          displayName: 'Gender',
          appearance: FacetAppearance.ToggleButtons
        },

        '@headless_category': {
          id: 'category',
          displayName: 'Category',
          appearance: FacetAppearance.Checkboxes
        },

        '@headless_color_bucket': {
          id: 'color',
          displayName: 'Color',
          appearance: FacetAppearance.Swatches
        },

        // TODO: Change this to sale/current price.
        '@headless_retail_price': {
          id: 'price',
          displayName: 'Price',
          appearance: FacetAppearance.ToggleButtons
        }
      },

      /**
       * Helper map that supplies necessary data to transform first-party
       * {@link IProductListFilter} objects to {@link ICoveoFacetRequest} objects.
       *
       * @see {@link ProductListService.filterToCoveoFacetRequest}
       */
      filtersToFacetsMap: {
        gender: {
          facetId: '@headless_gender',
          field: 'headless_gender',
          type: 'specific'
        },

        category: {
          facetId: '@headless_category',
          field: 'headless_category',
          type: 'hierarchical'
        },

        color: {
          facetId: '@headless_color_bucket',
          field: 'headless_color_bucket',
          type: 'specific'
        },

        price: {
          facetId: '@headless_retail_price',
          field: 'headless_retail_price',
          type: 'numericalRange'
        }
      }
    }
  }
});

export default coveo;
