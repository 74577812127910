import type {
  JSONObject,
  Primitive,
  PrimitiveLikeValue,
  StatelessImmutable
} from '@/type-utils';
import MemoryCache, { GetMethod } from '@/utils/MemoryCache';
import { InvalidPathError } from '@/utils/errors';
import { fastDeepMerge, traversePath } from '@/utils/object-utils';

import configs, {
  ConfigName,
  ConfigNamespaceSchema,
  ConfigSchema,
  ConfigValueSchema,
  EnvSpecificConfigValueSchema,
  EnvSpecificConfigValueSchemaWithDefault,
  ResolvedConfigNamespace,
  SiteLocaleKey,
  type Configs
} from '@/configs';
import { Site } from '@/constructs/Site';
import { Path as PathType, PathValue } from '@/type-utils/Path';
import { ConfigModel, ConfigModelType, IConfig } from '../../models/Config';
import { EnvironmentService } from '../EnvironmentService';
import I18NService, { Language, Country } from '../I18NService';

/** Represents the value that a `ConfigModel` holds. Can be a primitive or nested config. */
type ConfigValue<T> = T extends Primitive | Array<PrimitiveLikeValue>
  ? T
  : {
      [Key in keyof T]: T[Key] extends Primitive | Array<PrimitiveLikeValue>
        ? ConfigModelType<T[Key]>
        : ConfigModelType<ConfigValue<T[Key]>>;
    };

/** Represents default config values within the config, sans site, language, and country.  */
export type MergedConfig<T extends ConfigName> =
  Configs[T] extends ConfigSchema<infer R extends ConfigSchema<R>>
    ? ResolvedConfigNamespace<R['default']['default']>
    : never;

/** Represents a dot notation path to a location in the config object. */
export type ConfigPath<T extends object> = PathType<T>;

/** `Config` retrieves and resolves settings within a given config for the current site. */
class Config<T extends ConfigName> {
  /** A cache to store the config the resolved `ConfigModel`s by path. */
  private configModelCache = new MemoryCache<ConfigModel<unknown>>();

  private language: Language;
  private country: Country;
  private site: Site;

  /**
   * The site country key for the current site and country.
   * @returns The site country key, such as "SANUK-US".
   */
  private get SiteLocaleKey(): SiteLocaleKey {
    return `${this.site}-${this.country}`;
  }

  private resolvedConfig: Configs[T]['default']['default'];

  /**
   * @param configName - The name of the config which this object represents. Valid
   * configs can be found in the "src/configs/" directory.
   * @param [language] - The language specific for these config values. This is determined
   * heuristically, by default.
   * @param [country] - The country specific for these config values. This is determined
   * heuristically, by default.
   * @inheritDoc
   */
  public constructor(
    private configName: T,
    language?: Language,
    country?: Country
  ) {
    const underlyingConfig = configs[configName as unknown as T] as Configs[T];

    // If language or country is not passed, determine the values heuristically.
    // This may not be possible in some environments.
    if (!language || !country) {
      const { language: computedLanguage, country: computedCountry } =
        I18NService.currentLocale;
      this.language = language ?? computedLanguage;
      this.country = country ?? computedCountry;
    } else {
      this.language = language;
      this.country = country;
    }

    this.site = (process.env.NEXT_PUBLIC_SITE_BRAND.toUpperCase());

    const configsInIncreasingOrderOfSpecificity = [
      underlyingConfig.default[this.language],
      underlyingConfig[this.SiteLocaleKey]?.default,
      underlyingConfig[this.SiteLocaleKey]?.[this.language]
    ].filter((val) => val !== undefined);

    this.resolvedConfig = fastDeepMerge(
      underlyingConfig.default.default as ConfigNamespaceSchema<unknown>,
      ...configsInIncreasingOrderOfSpecificity
    ) as Configs[T]['default']['default'];
  }

  /**
   * Used to normalize the value at a given config location. Will return
   * the `configPart` as a `ConfigModel`. This handles different values
   * such as whether the `configPart` is a namespace within the config,
   * a configuration object with different values for dev, prod, or qa,
   * or just a normal primitive value or array thereof.
   * @param path - The path at which the setting value was located.
   * @param configPart - Some part of the config.
   * @returns A `ConfigModel` with the normalized value from the given
   * `configPart`.
   */
  private configFromConfigNamespaceSchema<
    ResolvedConfig extends MergedConfig<T>,
    Path extends PathType<ResolvedConfig>,
    Value = PathValue<ResolvedConfig, Path>
  >(
    path: Path,
    configPart: Value extends Primitive | Array<Primitive> | Array<JSONObject>
      ? ConfigValueSchema<Value>
      : ConfigNamespaceSchema<Value>
  ): ConfigModelType<ConfigValue<Value>> {
    // Determine the type of configuration part.
    // If the config part is an object and not an array,
    // see if it's a config part or environment specific setting value.
    if (
      (typeof configPart === 'object' && !(configPart instanceof Array)) ||
      (configPart instanceof Array && typeof configPart[0] === 'object')
    ) {
      const configPartObj = configPart as Record<string, unknown>;

      // If the config part is an environment specific setting value with
      // all values specified.
      if (
        'dev' in configPartObj &&
        'uat' in configPartObj &&
        'qa' in configPartObj &&
        'prod' in configPartObj
      ) {
        const configPartEnvSetting =
          configPartObj as EnvSpecificConfigValueSchema<Value>;
        return new ConfigModel<ConfigValue<Value>>({
          key: path,
          devValue: configPartEnvSetting.dev as ConfigValue<Value>,
          qaValue: configPartEnvSetting.qa as ConfigValue<Value>,
          uatValue: configPartEnvSetting.uat as ConfigValue<Value>,
          prodValue: configPartEnvSetting.prod as ConfigValue<Value>
        } as IConfig<ConfigValue<Value>>) as ConfigModelType<
          ConfigValue<Value>
        >;
      }

      // If the config part is an environment specific setting value with
      // some values specified and a default value.
      if (
        ('dev' in configPartObj ||
          'uat' in configPartObj ||
          'qa' in configPartObj ||
          'prod' in configPartObj) &&
        'default' in configPartObj
      ) {
        const configPartEnvSetting =
          configPartObj as EnvSpecificConfigValueSchemaWithDefault<Value>;
        return new ConfigModel<ConfigValue<Value>>({
          key: path as string,
          value: configPartEnvSetting.default as ConfigValue<Value>,
          devValue: configPartEnvSetting.dev as ConfigValue<Value>,
          uatValue: configPartEnvSetting.uat as ConfigValue<Value>,
          qaValue: configPartEnvSetting.qa as ConfigValue<Value>,
          prodValue: configPartEnvSetting.prod as ConfigValue<Value>
        }) as ConfigModelType<ConfigValue<Value>>;
      }

      // Otherwise, the object represents a namespace within the config.
      const mappedConfigObject: Record<string, unknown> = {};

      // Recursively get every deeper value and generate a `ConfigModel`
      // of that value.
      for (const [key] of Object.entries(configPart)) {
        mappedConfigObject[key] = this.getSetting(
          `${path as string}.${key}` as PathType<MergedConfig<T>>
        );
      }

      // Return the config namespace with all nested values resolved to
      // `ConfigModel`s.
      const configModel = new ConfigModel<ConfigValue<Value>>({
        key: path,
        value: mappedConfigObject as ConfigValue<Value>
      } as StatelessImmutable) as ConfigModelType<ConfigValue<Value>>;

      if (configPart instanceof Array) {
        const proxy = new Proxy(mappedConfigObject, {
          get: (target, prop) => {
            // If the requested property is the length of the array, return the length
            // from the original array.
            if (prop === 'length') {
              return configPart.length;
            }

            // If the requested property is an array method, call that array method
            // against this array-like config.
            if (prop in Array.prototype) {
              return (...args: Array<unknown>) =>
                /* eslint-disable @typescript-eslint/no-unsafe-return,
                    @typescript-eslint/no-unsafe-member-access,
                    @typescript-eslint/no-unsafe-call --
                    We can't know which array method was called. */
                Array.prototype[
                  prop as keyof ArrayConstructor['prototype']
                ].call(proxy, ...args);
              /* eslint-enable @typescript-eslint/no-unsafe-return,
                @typescript-eslint/no-unsafe-member-access,
                @typescript-eslint/no-unsafe-call */
            }

            // Otherwise, presume the property is on the original config object.
            return (target as Record<string | symbol, unknown>)[prop];
          }
        });

        return new ConfigModel<ConfigValue<Value>>({
          key: path,
          value: proxy as ConfigValue<Value>
        } as StatelessImmutable) as ConfigModelType<ConfigValue<Value>>;
      }

      // If it was not an array, return the config object as normal.
      return configModel;
    }

    // If the config part wasn't an object, it was an primitive or array.
    return new ConfigModel<ConfigValue<Value>>({
      key: path,
      value: configPart as Primitive | Array<Primitive> as ConfigValue<Value>
    } as StatelessImmutable) as ConfigModelType<ConfigValue<Value>>;
  }

  /**
   * Retrieves a setting value for a given path within the config object.
   * @param path - The path at which the setting value can be located.
   * @returns A `ConfigModel` whose underlying value was the value found
   * for the given setting path.
   */
  public getSetting<
    ResolvedConfig extends MergedConfig<T>,
    Path extends PathType<ResolvedConfig>,
    Value extends PathValue<ResolvedConfig, Path>
  >(path: Path): ConfigModelType<ConfigValue<Value>> {
    // Either get the item from the cache, or get the value from the underlying
    // config and store it in the cache for later retrieval.
    return this.configModelCache.get(
      path as string,
      () => {
        try {
          const configValue = traversePath(
            path as string,
            this.resolvedConfig as Record<string, unknown>
          );

          // Normalize the data for whatever value was found at this location
          // into a `ConfigModel`.
          return this.configFromConfigNamespaceSchema(
            path as PathType<MergedConfig<T>>,
            configValue as Value extends
              | Primitive
              | Array<Primitive>
              | Array<JSONObject>
              ? ConfigValueSchema<Value>
              : ConfigNamespaceSchema<Value>
          ) as ConfigModel<unknown>;
        } catch (e) {
          const errorMessage = `Path "${
            path as string
          }" was not found for config "${this.configName}"`;

          // If the config value was not found - throw.
          throw new InvalidPathError(errorMessage, {
            cause: e as Error
          });
        }
      },
      GetMethod.Sync
    ) as ConfigModelType<ConfigValue<Value>>;
  }
}

export default Config;
