import config from './config';

const help = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Most of them self explanatory. See documentation below.
       * @see https://help.gladly.com/implementation/docs/set-up-help-center
       */
      api: 'https://deckers.gladly.com',
      orgId: 'VMjbB8YTReWjBfYTWZ6IHA',
      brandId: 'sanuk.com',
      appId: 'sanuk.com',
      cdn: 'https://cdn.gladly.com',
      /** Selector of the element we want to insert the Help Center content into. */
      helpCenterSelector: '#gladly-help-center',
      /** This property will enable/disable the Help Center and the Chat widget.
       * (This can be seen on the /help-center page) */
      gladlyEnabled: true
    }
  },
  'AHNU-US': {
    default: {
      appId: 'deckers.com-ahnu.co',
      brandId: 'ZYXTMiPERNiZpxPkn9M8aw'
    }
  },
  'UGG-COLLAB-US': {
    default: {
      appId: 'ugg.com',
      brandId: 'ugg.com'
    }
  },
  'UGG-US': {
    default: {
      appId: 'ugg.com',
      brandId: 'ugg.com'
    }
  },
  'TEVA-US': {
    default: {
      appId: 'teva.com',
      brandId: 'teva.com'
    }
  },
  'KOOLABURRA-US': {
    default: {
      appId: 'koolaburra.com',
      brandId: 'koolaburra.com'
    }
  },
  'HOKA-US': {
    default: {
      appId: 'hoka.com',
      brandId: 'hoka.com'
    }
  }
});

export default help;
