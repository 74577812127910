/* eslint-disable @typescript-eslint/ban-types -- should be allowed for object
utils due to these utils possibly working for any object. */

import {
  EnumKeys,
  EnumToRecord,
  Expand,
  Id,
  JSONObject,
  JSPrimitive,
  Primitive,
  PrimitiveLikeValue,
  PrimitiveObject,
  StatelessImmutable,
  SwappedKeysAndValues,
  UnionToTuple
} from '@/type-utils';
import { Immutable } from '@/type-utils/Immutable';
import type { Path as PathType, PathValue } from '@/type-utils/Path';
import type { MergeWithCustomizer } from 'lodash';
import mergeWith from 'lodash/mergeWith';
import StaleWhileRevalidate from '../StaleWhileRevalidate';
import { InvalidArgumentError, InvalidPathError } from '../errors';

export { default as Hash } from './Hash';

/** Represents the possible overloads of `traversePath`. */
interface ITraversePath {
  /**
   * Accepts a path in dot notation and returns the value at the end of that path.
   * @param T - The type of the object to traverse.
   * @param Path - The type of the dotted hierarchical path for object `T`.
   * @param Value - The type of the value at the end of the path.
   * @param path - A path in dot notation for which we wish to retrieve the value.
   * @param objToTraverse - The object to which the path corresponds.
   * @returns The value at the end of the path.
   * @throws `InvalidPathError` if the path is invalid for the provided object.
   */
  <T, Path extends PathType<T>, Value extends PathValue<T, Path>>(
    path: Path,
    objToTraverse: T
  ): Value;

  /**
   * Accepts a path in dot notation and returns the value at the end of that path.
   * @param T - The type expected for the return value.
   * @param path - A path in dot notation for which we wish to retrieve the value.
   * @param objToTraverse - The object to which the path corresponds.
   * @returns The value at the end of the path.
   * @throws `InvalidPathError` if the path is invalid for the provided object.
   */
  <T>(path: string, objToTraverse: Record<string, unknown>): T;
}

/** @inheritDoc */
export const traversePath: ITraversePath = <
  T extends object,
  Path extends PathType<T>,
  Value extends PathValue<T, Path>
>(
  path: Path,
  objToTraverse: T
): Value => {
  const pathSegments = (path as string).split('.');
  let currentObject: {} = objToTraverse;
  let i = 0;

  for (const pathSegment of pathSegments) {
    if (!(pathSegment in currentObject)) {
      if (i === 0) {
        throw new InvalidPathError(
          `Property "${pathSegment}" not found in passed object.`
        );
      }
      throw new InvalidPathError(
        `Property "${pathSegment}" not found in object "${
          pathSegments[i - 1]
        }" for provided path "${path as string}"`
      );
    }

    currentObject = (currentObject as Record<string, unknown>)[
      pathSegment
    ] as {};

    i += 1;
  }

  return currentObject as Value;
};

/** Represents the possible overloads of `tryTraversePath`. */
interface ITryTraversePath {
  /**
   * Accepts a path in dot notation and returns the value at the end of that path.
   * @param T - The type of the object to traverse.
   * @param Path - The type of the dotted hierarchical path for object `T`.
   * @param Value - The type of the value at the end of the path.
   * @param path - A path in dot notation for which we wish to retrieve the value.
   * @param objToTraverse - The object to which the path corresponds.
   * @returns The value at the end of the path or `undefined` if the path does not exist.
   */
  <T, Path extends PathType<T>, Value extends PathValue<T, Path>>(
    path: Path,
    objToTraverse: T
  ): Value | undefined;

  /**
   * Accepts a path in dot notation and returns the value at the end of that path.
   * @param T - The type expected for the return value.
   * @param path - A path in dot notation for which we wish to retrieve the value.
   * @param objToTraverse - The object to which the path corresponds.
   * @returns The value at the end of the path or `undefined` if the path does not exist.
   */
  <T>(path: string, objToTraverse: Record<string, unknown>): T;
}

/** @inheritDoc */
export const tryTraversePath: ITryTraversePath = <
  T,
  Path extends PathType<T>,
  Value extends PathValue<T, Path>
>(
  path: Path,
  objToTraverse: T
): Value | undefined => {
  // Try to get the value at the path.
  try {
    return traversePath(path, objToTraverse) as Value;
  } catch (e) {
    // If the path was invalid, return `undefined`.
    if (e instanceof InvalidPathError) {
      return undefined;
    }

    // If some other exception occurred - rethrow.
    throw e;
  }
};

/** Represents the possible overloads of `toStatelessImmutable`. */
interface IToStatelessImmutable {
  /**
   * A generic approach to transforming a stateful object instanced into a stateless
   * and immutable version thereof. The resultant object will have no promises or
   * functions on it.
   * @param mutableObj - The input object from which the statless and immutable version
   * should be generated.
   * @param T - The type of the input object.
   * @returns A stateless and immutable version of the input object.
   */
  <T>(mutableObj: T): StatelessImmutable<T>;

  /**
   * A generic approach to transforming a stateful object instanced into a stateless
   * and immutable version thereof. The resultant object will have no promises or
   * functions on it.
   * @param mutableObj - The input object from which the statless and immutable version
   * should be generated.
   * @param T - The type of the input object.
   * @returns A stateless and immutable version of the input object.
   */
  <T>(mutableObj: T): StatelessImmutable<T>;
}

/**
 * A generic approach to transforming a stateful object instanced into a stateless
 * and immutable version thereof. The resultant object will have no promises or
 * functions on it.
 * @param mutableObj - The input object from which the statless and immutable version
 * should be generated.
 * @returns A stateless and immutable version of the input object.
 */
export const toStatelessImmutable: IToStatelessImmutable = <T extends {}>(
  mutableObj: T
): StatelessImmutable<T> => {
  const statelessImmutableObj: Record<string, unknown> = {};

  /**
   * Determines whether a value should be included in the resultant stateless object.
   * @param value - The value to check.
   * @returns Whether the given value was value for inclusion in the resultant object.
   */
  const isValueInvalid = (value: unknown): boolean =>
    value instanceof Promise || value instanceof Function;

  /**
   * Traverses a given map and generates a map of valid stateless values.
   * @param map - A map to generate a new map of stateless values from.
   * @returns A map of stateless values generated from input map.
   */
  const traverseMap = (
    map: Map<unknown, unknown>
  ): ReadonlyMap<unknown, unknown> => {
    const mappedMap: Map<unknown, unknown> = new Map();

    for (const [key, value] of map.entries()) {
      const processedValue = valueHandler(value);
      if (typeof processedValue !== 'undefined') {
        mappedMap.set(key, processedValue);
      }
    }

    return mappedMap;
  };

  /**
   * Traverses a given set and generates a set of valid stateless values.
   * @param set - A set to generate a new set of stateless values from.
   * @returns A set of stateless values generated from input set.
   */
  const traverseSet = (set: Set<unknown>): ReadonlySet<unknown> => {
    const mappedSet: Set<unknown> = new Set();

    for (const [value] of set.entries()) {
      const processedValue = valueHandler(value);
      if (typeof processedValue !== 'undefined') {
        mappedSet.add(processedValue);
      }
    }

    return mappedSet;
  };

  /**
   * Traverses a given array and generates an array of valid stateless values.
   * @param arr - An array to generate a new array of stateless values from.
   * @returns An array of stateless values generated from input array.
   */
  const traverseArray = (arr: Array<unknown>): ReadonlyArray<unknown> => {
    return Object.freeze(
      arr
        .filter((item) => !isValueInvalid(item))
        .map((item) => {
          return valueHandler(item);
        })
    );
  };

  /**
   * Processes the provided value based on its type. Generates a value for the
   * resultant stateless object.
   * @param value - The value to process.
   * @returns Either the original value, a stateless presentation of it, or `undefined` if
   * the value was not valid.
   */
  const valueHandler = (value: unknown): unknown => {
    // If this is a WeakRef, dereference the value to be consumed by this function.
    if (value instanceof WeakRef) {
      value = value.deref();
    }

    // TODO: Add a way to handle circular structures.

    // If the value is an instance of SWR, deference the stale value.
    if (value instanceof StaleWhileRevalidate) value = value.value;

    // If the value is a promise or a function, do not assign.
    if (isValueInvalid(value)) return undefined;

    // Else, if the value is an object we should traverse it recursively.
    if (value instanceof Object) {
      // Crawl any nested traversable objects such as arrays, maps, sets, and nested objects.
      if (value instanceof Array) {
        return traverseArray(value);
      }
      if (value instanceof Map) {
        return traverseMap(value);
      }
      if (value instanceof Set) {
        return traverseSet(value);
      }
      return toStatelessImmutable<unknown>(value);
    }

    return value;
  };

  // Loop over the key/value pairs of the provided object, create a "stateless object" from it.
  for (const [key, value] of Object.entries(mutableObj)) {
    const processedValue = valueHandler(value);

    // If the processed value returned `undefined`, it means it should not be included
    // in the resultant object.
    if (typeof processedValue === 'undefined') continue;

    // If the value was valid, assign the processed value.
    statelessImmutableObj[key] = processedValue;
  }

  return Object.freeze(
    statelessImmutableObj
  ) as unknown as StatelessImmutable<T>;
};

/**
 * Takes a record-like object of key / value pairs and outputs an corresponding map.
 * @param obj - The object to generate the map from.
 * @returns A map whose keys and values match that of the input object.
 */
export const objectToMap = <K extends string | symbol | number, V>(
  obj: Record<K, V>
): Map<K, V> => {
  const map = new Map<K, V>();
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) map.set(key, obj[key]);
  }
  return map;
};

/**
 * Determines whether a given object is a JavaScript iterable type.
 * @param obj - The object to test whether it is an iterable.
 * @returns Whether the object was an iterable type.
 */
export const isIterable = (obj: Object): obj is Iterable<unknown> =>
  typeof (obj as Record<typeof Symbol.iterator, unknown>)[Symbol.iterator] ===
  'function';

/**
 * Determines whether a given object is empty (equivalent to `{}` or `new Object()`).
 * @param obj - Object to test.
 * @returns `true` if the object is empty.
 */
export const isEmpty = (obj: Object): obj is Record<string, never> => {
  // Kudos to https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
  return (
    obj && // null and undefined check
    Object.keys(obj).length === 0 && // no keys
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

/**
 * Determines whether a given value is an object.
 * @param val - Value to test.
 * @returns `true` if the value is indeed an object.
 */
export const isObject = (val: unknown): val is Object => {
  // Kudos to https://stackoverflow.com/a/8511350
  return typeof val === 'object' && !Array.isArray(val) && val !== null;
};

/**
 * Inner function for recursively flattening an object.
 *
 * @param obj - An object to flatten.
 * @param keyParts - Object keys from outer objects.
 *
 * @returns List of [value, keyParts] pairs.
 */
const flattenObjectInner = (
  obj: PrimitiveLikeValue,
  keyParts: Array<string> = []
): Array<[JSPrimitive, Array<string>]> => {
  if (Array.isArray(obj)) {
    return obj.flatMap((value, index) =>
      flattenObjectInner(value, [...keyParts, index.toString()])
    );
  }

  if (
    obj === null ||
    typeof obj === 'undefined' ||
    typeof obj === 'string' ||
    typeof obj === 'boolean' ||
    typeof obj === 'number' ||
    typeof obj === 'bigint' ||
    typeof obj === 'symbol'
  ) {
    return [[obj, keyParts]];
  }

  return Object.entries(obj).flatMap(([key, value]) =>
    flattenObjectInner(value, [...keyParts, key])
  );
};

/**
 * Recursively flattens an object, such that it becomes a record with primitive values.
 *
 * @param obj - An object to flatten.
 * @returns Flattened object.
 */
// This overload enables us to narrow the return type. It must
// be present first in the overload list, as TypeScript chooses
// the first matching overload when resolving types.
export function flattenObject<AllowNull extends boolean>(
  obj: JSONObject<AllowNull>
): Record<string, Primitive<AllowNull>>;
export function flattenObject(
  obj: PrimitiveObject
): Record<string, JSPrimitive>;
// eslint-disable-next-line jsdoc/require-jsdoc -- It's defined above.
export function flattenObject(
  obj: PrimitiveObject
): Record<string, JSPrimitive> {
  return Object.fromEntries(
    flattenObjectInner(obj, []).map(([value, keyParts]) => [
      keyParts.join('.'),
      value
    ])
  );
}

/**
 * Accepts an enum parameter and returns a object key / value representation of that enum.
 * @param enumToConvert - The enum to convert to an object.
 * @returns An object whose keys and values map to the enum.
 * @example ```ts
 * enum Things {
 *   Hello = 'world',
 *   Something = 'something else'
 * }
 *
 * const obj = objectFromEnum(Things);
 * // obj === { Hello: 'world', Something: 'something else' };
 * ```
 */
export const objectFromEnum = <Enum extends {}>(
  enumToConvert: Enum
): Expand<EnumToRecord<Enum>> => {
  const enumKeys = Object.keys(enumToConvert) as unknown as UnionToTuple<
    EnumKeys<Enum>
  >;
  const enumValues = (enumKeys as Array<any>).map(
    (value: keyof typeof enumToConvert) => enumToConvert[value]
  );
  const returnVal: Record<string, string | number> = {};
  for (let i = 0; i < enumKeys.length; i++) {
    returnVal[enumKeys[i] as string] = enumValues[i] as unknown as
      | string
      | number;
  }

  return returnVal as Expand<EnumToRecord<Enum>>;
};

/**
 * Swaps the keys and values of an object so the values becomes the keys and the keys
 * become the values. This does not mutate the source object, but instead creates a
 * new object with the swapped keys and values.
 * @param obj - The source object from which to create the new swapped object.
 * @returns A new object that is the same as the input source object, but with the
 * keys and values swapped.
 * @example ```ts
 * const swapped = swapKeysAndValue({ hello: 'world', goodbye: 0 });
 * // swapped === { world: 'hello', 0: 'goodbye' }
 * ```
 */
export const swapKeysAndValue = <
  T extends Record<string | number, string | number>
>(
  obj: T
): Expand<SwappedKeysAndValues<T>> => {
  const returnVal = {} as Record<string | number, string | number>;
  Object.entries(obj).forEach(([key, value]) => {
    const valueAsStrOrNum = value as string | number;
    returnVal[valueAsStrOrNum] = key;
  });
  return returnVal as Expand<SwappedKeysAndValues<T>>;
};

/**
 * A utility type that removes `undefined` from property types and instead
 * marks selected properties as optional.
 */
type RemoveUndefined<T extends object> = {
  // Select only keys including an `undefined` type.
  [K in keyof T as undefined extends T[K] ? K : never]?: Exclude<
    T[K],
    undefined
  >;
} & {
  // Select only keys that are definitely not `undefined`.
  [K in keyof T as undefined extends T[K] ? never : K]: T[K];
};

/**
 * Given an object, modifies the object by removing all keys
 * that have `undefined` value.
 * @param obj - Object.
 * @returns Same object reference.
 */
export const removeUndefined = <T extends object>(
  obj: T
): RemoveUndefined<T> => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }

  return obj as unknown as RemoveUndefined<T>;
};

/**
 * A utility type that removes `undefined | null` from property types and instead
 * marks selected properties as optional.
 */
type RemoveNullish<T extends object> = {
  // Select only keys including `null` or `undefined` types.
  [K in keyof T as undefined extends T[K]
    ? K
    : null extends T[K]
      ? K
      : never]?: Exclude<T[K], undefined | null>;
} & {
  // Select only keys that are definitely not `null` or `undefined`.
  [K in keyof T as undefined extends T[K]
    ? never
    : null extends T[K]
      ? never
      : K]: T[K];
};

/**
 * Given an object, modifies the object by removing all keys
 * that have either a `null` or an `undefined` value.
 * @param obj - Object.
 * @returns Same object reference.
 */
export const removeNullish = <T extends object>(obj: T): RemoveNullish<T> => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  }

  return obj as unknown as RemoveNullish<T>;
};

/**
 * A helper type that performs the a deep merge on two objects.
 * Specifically, this type is responsible for deciding whether
 * a property type is merged recursively, or replaced by a newer type.
 *
 * @example
 * type mergedType = DeepMergeTwoTypes<
 *  { a: { b: string }; c: { d: number } },
 *  { a: number; b: string },
 * >; // { a: number, b: string, c: { d: number }}
 */
type DeepMergeTwoTypes<T extends object, U extends object> = Omit<
  T,
  keyof U
> & {
  [K in keyof U]: K extends keyof T // if K is a keyof T
    ? U[K] extends undefined // check if U[K] is undefined
      ? T[K] // if so, let the merged type be T[K]
      : T[K] extends object // else check if T[K] is an object
        ? U[K] extends object // and check if U[K] is an object
          ? DeepMerge<[T[K], U[K]]> // and deep merge them if so
          : U[K] // otherwise let U[K] replace T[K]
        : U[K] // otherwise let U[K] replace T[K]
    : U[K]; // otherwise, add U[K] to the result
};

/**
 * A type that performs a deep merge on a list of objects.
 * Nested objects are merged recursively, and non-object property types
 * (including arrays) replace the type of earlier matching properties.
 *
 * This is not exported because it's only useful as a result of calling
 * the `deepMerge` utility function.
 *
 * @example
 * type mergedType = DeepMerge<
 * [
 *  { a: { b: string }; c: { d: number } },
 *  { a: number; b: string },
 *  { c: { e: boolean } }
 * ]
 * >; // { a: number, b: string, c: { d: number, e: boolean }}
 */
type DeepMerge<TArr extends ReadonlyArray<object>> = Id<
  TArr extends readonly [
    infer L extends object,
    ...infer R extends ReadonlyArray<object>
  ]
    ? R extends []
      ? L
      : DeepMergeTwoTypes<L, R extends [any] ? R[0] : DeepMerge<R>>
    : object
>;

/**
 * A {@link mergeWith} customizer which returns a deep copy of the source value
 * if it's an array, or `undefined` to use the default behavior otherwise.
 * In other words, this overrides the default behavior for arrays to replace
 * previous assignments rather than merge recursively.
 * @param _ - Ignored.
 * @param srcVal - Any primitive-like value from the source object.
 * @returns A deep copy of the given primitive-like value.
 * @see {@link MergeWithCustomizer}
 */
function deepMergeCustomizer(
  _: PrimitiveLikeValue,
  srcVal: PrimitiveLikeValue
): PrimitiveLikeValue {
  return (
    Array.isArray(srcVal) ? mergeWith([], srcVal) : undefined
  ) as PrimitiveLikeValue;
}

/**
 * Deep clones and merges a list of primitive-like objects.
 *
 * **Notes**:
 * - `Symbols` are copied by reference since they cannot be cloned.
 * - Subsequent sources overwrite property assignments of previous sources,
 * unless the subsequent value is `undefined`.
 * - This works even if a source object is circular.
 *
 * @param sources - The list of objects to deep merge.
 * @returns A deep-merged copy of the list of objects.
 * @throws {InvalidArgumentError} If the function is called with no arguments.
 * @example
 * deepMerge(
 *  { a: 1, b: [1,2,3], c: { d: 2 }},
 *  { a: "hello", b: undefined, c: { e: 3 } }
 * ) // { a: "hello", b: [1,2,3], c: { d: 2, e: 3 } }
 */
// The object types are constrained to be `PrimitiveObjects` because complex
// objects might not be cloned, and may even be mutated.
export function deepMerge<
  ObjArr extends readonly [PrimitiveObject, ...Array<PrimitiveObject>]
>(...sources: ObjArr): DeepMerge<ObjArr> {
  if (sources.length === 0)
    throw new InvalidArgumentError('deepMerge expects at least 1 argument.');

  return mergeWith({}, ...sources, deepMergeCustomizer) as DeepMerge<
    typeof sources
  >;
}

/**
 * A helper function for {@link fastDeepMerge} that performs the actual recursion
 * and a couple of optimizations compared to {@link deepMerge}.
 * @param dest - The destination object.
 * @param sources - The list of objects to deep merge into `dest`.
 * @returns A deep-merged copy of the list of objects.
 */
function fastDeepMergeHelper(
  dest: { [key: string]: unknown },
  ...sources: ReadonlyArray<object>
): object {
  for (const src of sources) {
    for (const [key, srcVal] of Object.entries(src)) {
      const destVal = dest[key];
      if (isObject(srcVal) && isObject(destVal)) {
        // Shallow copy the destination object to ensure we don't
        // accidentally mutate a source object.
        dest[key] = fastDeepMergeHelper({ ...destVal }, srcVal);
      } else if (srcVal !== undefined) {
        dest[key] = srcVal;
      }
    }
  }
  return dest;
}

/**
 * A faster, more flexible deep merge without deep cloning.
 * Source objects are only cloned under the possibility of
 * being mutated.
 *
 * **Notes**:
 * - `undefined` values are ignored and stripped.
 * - Objects are only cloned as necessary, therefore
 * the result type is deeply immutable.
 * - Additionally, object prototypes and non-enumerable
 * properties may not be preserved.
 * - May not work if the sources are circular.
 *
 * @param sources - The list of objects to deep merge.
 * @returns A deep-merged copy of the list of objects.
 * @throws {InvalidArgumentError} If the function is called with no arguments.
 * @example
 * fastDeepMerge(
 *  { a: 1, b: [1,2,3], c: { d: 2 }},
 *  { a: "hello", b: undefined, c: { e: 3 } }
 * ) // { a: "hello", b: [1,2,3], c: { d: 2, e: 3 } }
 */
export function fastDeepMerge<
  const ObjArr extends readonly [object, ...Array<object>]
>(...sources: ObjArr): Immutable<DeepMerge<ObjArr>> {
  if (sources.length === 0) {
    throw new InvalidArgumentError(
      'fastDeepMerge expects at least 1 argument.'
    );
  }
  return fastDeepMergeHelper({}, ...sources) as Immutable<DeepMerge<ObjArr>>;
}
