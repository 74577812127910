import config from './config';

const test = config({
  default: {
    default: {
      test1: {
        test2: {
          test3: 'hello world',
          dontOverrideThis: 'ok'
        }
      },
      testPhrase: 'hello world',
      test4: { dev: true, qa: false, prod: false, uat: false },
      test5: { qa: true, default: false },
      test6: 'a',
      locale: 'US',
      test7: ['a', 1, { b: 'c' }]
    },
    en: {
      test6: 'b',
      testPhrase: 'hello world in English'
    },
    fr: {
      testPhrase: 'Bonjour le monde'
    }
  },
  'SANUK-US': {
    default: {
      test1: {
        test2: {
          test3: 'good bye world'
        }
      },
      test6: 'c'
    },
    en: {
      test6: 'd'
    }
  }
});

export default test;
