/**
 * Enumerates supported US state codes as
 * [USPS Postal Codes](https://pe.usps.com/text/pub28/28apb.htm).
 */
export enum USState {
  /**
   * Represents **Alaska** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  AK = 'AK',

  /**
   * Represents **Alabama** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  AL = 'AL',

  /**
   * Represents **Arkansas** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  AR = 'AR',

  /**
   * Represents **Arizona** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  AZ = 'AZ',

  /**
   * Represents **Colorado** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  CO = 'CO',

  /**
   * Represents **California** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  CA = 'CA',

  /**
   * Represents **District of Columbia** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  DC = 'DC',

  /**
   * Represents **Delaware** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  DE = 'DE',

  /**
   * Represents **Connecticut** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  CT = 'CT',

  /**
   * Represents **Georgia** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  GA = 'GA',

  /**
   * Represents **Florida** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  FL = 'FL',

  /**
   * Represents **Idaho** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  ID = 'ID',

  /**
   * Represents **Hawaii** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  HI = 'HI',

  /**
   * Represents **Indiana** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  IN = 'IN',

  /**
   * Represents **Illinois** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  IL = 'IL',

  /**
   * Represents **Kansas** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  KS = 'KS',

  /**
   * Represents **Iowa** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  IA = 'IA',

  /**
   * Represents **Louisiana** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  LA = 'LA',

  /**
   * Represents **Kentucky** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  KY = 'KY',

  /**
   * Represents **Maryland** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  MD = 'MD',

  /**
   * Represents **Maine** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  ME = 'ME',

  /**
   * Represents **Michigan** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  MI = 'MI',

  /**
   * Represents ** Massachusetts** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  MA = 'MA',

  /**
   * Represents **Mississippi** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  MS = 'MS',

  /**
   * Represents **Minnesota** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  MN = 'MN',

  /**
   * Represents **Montana** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  MT = 'MT',

  /**
   * Represents **Missouri** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  MO = 'MO',

  /**
   * Represents **Nevada** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  NV = 'NV',

  /**
   * Represents **Nebraska** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  NE = 'NE',

  /**
   * Represents **New Jersey** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  NJ = 'NJ',

  /**
   * Represents **New Hampshire** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  NH = 'NH',

  /**
   * Represents **New York** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  NY = 'NY',

  /**
   * Represents **	New Mexico** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  NM = 'NM',

  /**
   * Represents **	North Dakota** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  ND = 'ND',

  /**
   * Represents **North Carolina** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  NC = 'NC',

  /**
   * Represents **Oklahoma** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  OK = 'OK',

  /**
   * Represents **Ohio** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  OH = 'OH',

  /**
   * Represents **Pennsylvania** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  PA = 'PA',

  /**
   * Represents **Oregon** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  OR = 'OR',

  /**
   * Represents **South Carolina** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  SC = 'SC',

  /**
   * Represents **Rhode Island** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  RI = 'RI',

  /**
   * Represents **Tennessee** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  TN = 'TN',

  /**
   * Represents **South Dakota** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  SD = 'SD',

  /**
   * Represents **Utah** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  UT = 'UT',

  /**
   * Represents **Texas** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  TX = 'TX',

  /**
   * Represents **Trust Territories** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  TT = 'TT',

  /**
   * Represents **Virginia** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  VA = 'VA',

  /**
   * Represents **Vermont** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  VT = 'VT',

  /**
   * Represents **West Virginia** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  WV = 'WV',

  /**
   * Represents **Washington** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  WA = 'WA',

  /**
   * Represents **Wyoming** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  WY = 'WY',

  /**
   * Represents **Wisconsin** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  WI = 'WI'
}
