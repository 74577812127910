/**
 * Represents an ISO 3166-1 alpha-2 country code identifier.
 * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
 */
export enum Country {
  /**
   * Represents the United States as an ISO 3166-1 alpha-2 country code identifier.
   */
  US = 'US',

  /**
   * Represents United Kingdom as an ISO 3166-1 alpha-2 country code identifier.
   */
  UK = 'UK',

  /**
   * Represents Canada as an ISO 3166-1 alpha-2 country code identifier.
   */
  CA = 'CA',

  /**
   * Represents Great Britain as an ISO 3166-1 alpha-2 country code identifier.
   */
  GB = 'GB',

  /**
   * Represents Germany as an ISO 3166-1 alpha-2 country code identifier.
   */
  DE = 'DE',

  /**
   * Represents France as an ISO 3166-1 alpha-2 country code identifier.
   */
  FR = 'FR',

  /**
   * Represents Italy as an ISO 3166-1 alpha-2 country code identifier.
   */
  IT = 'IT',

  /**
   * Represents Japan as an ISO 3166-1 alpha-2 country code identifier.
   */
  JP = 'JP',

  /**
   * Represents the Netherlands as an ISO 3166-1 alpha-2 country code identifier.
   */
  NL = 'NL'
}
