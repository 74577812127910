import config from './config';

const carriers = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * The `carrierRegex` is defining regular expressions for
       * different carriers to match tracking numbers. Each object in the array represents a specific
       * carrier and its corresponding regex pattern. */
      carrierRegex: [
        {
          carrier: 'ontrac',
          regex: '\\b[c-dC-D]{1}\\d{14}\\b'
        },
        {
          carrier: 'canadapost',
          regex: '\\b\\d{16}\\b'
        },
        {
          carrier: 'ups',
          regex:
            '\\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\\dT]\\d\\d\\d ?\\d\\d\\d\\d ?\\d\\d\\d)\\b'
        },
        {
          carrier: 'fedex',
          regex: '(\\b96\\d{20}\\b)|(\\b\\d{15}\\b)|(\\b\\d{12}\\b)'
        },
        {
          carrier: 'newgistics',
          regex: '\\d{22}|[0-9A-Z]{11}US$'
        },
        {
          carrier: 'dpd',
          regex: '[0-9]{14}[A-Z0-9]?'
        },
        {
          carrier: 'dpduk',
          regex: '[0-9]{14}[A-Z0-9]?'
        },
        {
          carrier: 'kuronekoyamato',
          regex: '[0-9]{14}[A-Z0-9]?'
        }
      ]
    }
  }
});

export default carriers;
