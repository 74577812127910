import config from './config';

const returns = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Available returns label services. */
      labelServices: 'ups',
      /** If enabled there will be a UI option to request a QRCode. */
      enableQRCodes: false,
      /** QRCode is the default label option. */
      qrCodeDefault: false,
      /** Option to enable or disable QR code functionality in Returns Review step. */
      showQRCode: false,

      flow: {
        /** The base location for the returns flow. */
        baseLocation: '/returns',

        /**
         * The location the customer will be redirected to when triggering exchange mode
         * (i.e. Starting an uneven exchange).
         */
        exchangeModeRedirectLocation: '/c/all'
      },

      /** The carrier that will deliver a placed return order. */
      carrier: 'USP',

      /** The default tracking number for Retail Returns. */
      retailTrackingNumber: '0',

      /** Option to enable or disable Print Label functionality in Returns Review step. */
      showPrintLabel: true,

      /** Option to enable or disable shipping address in Returns Review step. */
      showShippingAddress: true,

      /** Default shipping address for when the shipping address form is disabled in Returns Review step.  */
      defaultShippingAddress: {
        firstName: 'Deckers Brands',
        lastName: 'Returns Department',
        street: '17791 N. Perris Blvd.',
        street2: 'National Logistics Services',
        city: 'Moreno Valley',
        state: 'CA',
        zipCode: '92551',
        country: 'US',
        phoneNumber: '8059677611'
      },
      // If `true` the exchange flow will be shown in Returns.
      showExchangeFlow: false,

      /*
       * If `true` the Retail and Gift Return flow will be shown in Returns Landing page.
       * Retail and Gift Return flow is a flow where the customer can return a product
       * without an order number.
       */
      showRetailAndGiftReturn: false
    }
  },
  'AHNU-US': {
    default: {
      /** Default shipping address for when the shipping address form is disabled in Returns Review step.  */
      defaultShippingAddress: {
        firstName: 'Deckers Brands',
        lastName: 'Returns Department',
        street: '17791 N. Perris Blvd.',
        city: 'Moreno Valley',
        state: 'CA',
        zipCode: '92551',
        country: 'US',
        phoneNumber: '8059677611'
      },

      // If `true` the exchange flow will be shown in Returns.
      showExchangeFlow: false
    }
  }
});

export default returns;
