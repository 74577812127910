import { DOMS_OAUTH_CLIENT_ID, DOMS_OAUTH_CLIENT_SECRET } from '../env/server';

import config from '../config';

const doms = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Configuration for the various DOMS JERRY services.
       */
      jerry: {
        /**
         * Between 'pci' and '.doms' is the DOMS environment, it can be: 'dev', 'qa', 'uat', 'prod'.
         * The 'dev' environment can be unstable, but has the most up to date
         * code.
         *
         * This should match the aws environment that is being used.
         *
         * Prod: 'https://dwwebservices.deckers.com/JerryRestServices/api/1.0.0'.
         */
        baseUrl: {
          default: 'https://payment-gateway-pci-uat.doms.deckers.com/jerry/api',
          qa: 'https://payment-gateway-pci-qa.doms.deckers.com/jerry/api',
          prod: 'https://payment-gateway-pci.doms.deckers.com/jerry/api'
        },
        /**
         * This endpoint is used to authenticate orders through the
         * DOMS JERRY Service.
         */
        processEndpoint: '/v2.0/process-request',
        /**
         * This endpoint is used to check gift card balances.
         */
        giftCardEndpoint: '/v1.0/gift-card-balance'
      },

      /**
       * Gets the OAUTH token from DOMS, which is used to authenticate
       * other calls like the JERRY service.
       */
      oauth: {
        baseUrl: {
          default:
            'https://auth-deckers-doms-non-prod.auth.us-west-2.amazoncognito.com',
          prod: 'https://auth-deckers-doms-prod.auth.us-west-2.amazoncognito.com'
        },
        endpoint: '/oauth2/token',
        clientId: DOMS_OAUTH_CLIENT_ID as string,
        clientSecret: DOMS_OAUTH_CLIENT_SECRET as string
      },

      /**
       * Configuration values for DOMS Mail microservice.
       */
      mail: {
        baseUrl: {
          default: 'https://mail-uat.doms.deckers.com/api/v1.0.0',
          qa: 'https://mail-qa.doms.deckers.com/api/v1.0.0',
          prod: 'https://mail.doms.deckers.com/api/v1.0.0'
        }
      }
    }
  }
});

export default doms;
