import config from '../config';

import { PITNEYBOWES_API_KEY } from '../env/server';

/** Configuration for the PitneyBowes integration. */
const pitneybowes = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** The baseUrl for both auth and label calls. */
      baseUrl: 'https://api.pitneybowes.com',
      /** This public key is used to acquire the token used to auth calls. */
      apiKey: PITNEYBOWES_API_KEY as string,
      /** How long should the order length be, the order number will be extended to this number. */
      orderNoLength: 17,
      /** The regex used to change the order number. */
      orderRegex: '[-_]',
      /** The starting position. */
      trackingNumberStartIndexPosition: 8,
      /** The client id, this is brand specific and placed in the request body. */
      clientID: 'SNUK',
      /** The shipper id is placed in the request body. */
      shipperID: '3002199443',
      /** The PitneyBowes pickup location URL. */
      pickupUrl: 'https://tools.usps.com/find-location.htm',
      /** The PitneyBowes dropoff location URL. */
      dropoffUrl: 'https://tools.usps.com/find-location.htm'
    }
  },
  'AHNU-US': {
    default: {
      clientID: 'AHNU'
    }
  }
});

export default pitneybowes;
