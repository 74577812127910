import { Language } from '@/constructs/Language';
import { Country } from '@/constructs/Country';
import type {
  RegionMetadata,
  CountryMetadata,
  CountrySettingsForSite
} from '@/constructs/LocaleSchema';
import { Region } from '@/constructs/Region';
import config from './config';

/** Contains metadata for all configured countries. */
const ALL_REGIONS = {
  [Region.NA]: {
    aliases: {
      [Language.EN]: 'North America',
      [Language.DE]: 'Nordamerika',
      [Language.ES]: 'América del Norte',
      [Language.FR]: 'Amérique du Nord'
    }
  },
  [Region.EMEA]: {
    aliases: {
      [Language.EN]: 'Europe, Middle East, and Africa',
      [Language.DE]: 'Europa, Naher Osten und Afrika',
      [Language.ES]: 'Europa, Medio Oriente y África',
      [Language.FR]: 'Europe, Moyen-Orient et Afrique'
    }
  }
} as const satisfies RegionMetadata;

/** Contains metadata for all configured countries. */
const ALL_COUNTRIES = {
  [Country.US]: {
    regionID: Region.NA,
    iconPath: 'images/flags/4x3/us.svg',
    aliases: {
      [Language.EN]: 'United States',
      [Language.DE]: 'Vereinigte Staaten',
      [Language.FR]: 'États-Unis',
      [Language.ES]: 'Estados Unidos'
    }
  },
  [Country.CA]: {
    regionID: Region.NA,
    iconPath: 'images/flags/4x3/ca.svg',
    aliases: {
      [Language.EN]: 'Canada',
      [Language.DE]: 'Kanada',
      [Language.ES]: 'Canadá',
      [Language.FR]: 'Canada'
    }
  },
  [Country.DE]: {
    regionID: Region.EMEA,
    iconPath: 'images/flags/4x3/de.svg',
    aliases: {
      [Language.EN]: 'Germany',
      [Language.DE]: 'Deutschland',
      [Language.ES]: 'Alemania',
      [Language.FR]: 'Allemagne'
    }
  }
} as const satisfies CountryMetadata;

/** Contains country-specific settings for Ahnu. */
const AHNU_COUNTRY_SETTINGS = {
  [Country.US]: {
    enabled: true,
    defaultLanguage: Language.EN,

    languages: {
      [Language.EN]: {
        enabled: true,
        url: 'https://ahnu.co'
      }
    }
  }
} as const satisfies CountrySettingsForSite;

/** Contains country-specific settings for mock purposes. */
const MOCK_COUNTRY_SETTINGS = {
  [Country.US]: {
    enabled: true,
    defaultLanguage: Language.EN,

    languages: {
      [Language.EN]: {
        enabled: true,
        url: 'https://ahnu.co'
      }
    }
  },
  [Country.CA]: {
    enabled: true,
    defaultLanguage: Language.EN,

    languages: {
      [Language.EN]: {
        enabled: true,
        url: 'https://ahnu.ca/en_CA'
      },
      [Language.FR]: {
        enabled: true,
        url: 'https://ahnu.ca/fr_CA'
      }
    }
  },
  [Country.DE]: {
    enabled: true,
    defaultLanguage: Language.DE,

    languages: {
      [Language.DE]: {
        enabled: true,
        url: 'https://ahnu.co/de_DE'
      }
    }
  }
} as const satisfies CountrySettingsForSite;

const locale = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      countries: ALL_COUNTRIES,
      regions: ALL_REGIONS,

      // TODO: Remove this after QA/UAT are done
      countrySettings: MOCK_COUNTRY_SETTINGS // Each site should overwrite this.
    },

    'AHNU-US': {
      countrySettings: AHNU_COUNTRY_SETTINGS
    }
  }
});

export default locale;
