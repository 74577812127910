import config from './config';
import ConfiguredMediaSize from './types/ConfiguredMediaSize';

const media = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Configured image sizes.
       * By default, an image will take the smallest configured
       * size that will fill its container.
       */
      imageSizes: {
        [ConfiguredMediaSize.ExtraSmall]: 50,
        [ConfiguredMediaSize.Small]: 140,

        [ConfiguredMediaSize.PDPSliderThumbLarge]: 137,
        [ConfiguredMediaSize.PDPSliderThumbLarge2x]: 274,

        [ConfiguredMediaSize.LifestyleThumbLarge]: 137,
        [ConfiguredMediaSize.LifestyleThumbLarge2x]: 274,

        [ConfiguredMediaSize.LifestyleDISSmall]: 750,
        [ConfiguredMediaSize.LifestyleDISLarge]: 1500,

        [ConfiguredMediaSize.PDPSliderSmall]: 555,
        [ConfiguredMediaSize.PDPSliderLarge]: 1100,

        [ConfiguredMediaSize.WishlistTitle]: 303
      },

      /**
       * URL of the image that will be rendered on
       * load failure.
       */
      placeholderImageURL:
        'https://dms.deckers.com/sanuk/image/upload/placeholders/image.png'
    }
  }
});

export default media;
