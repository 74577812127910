import { DTO, JSONValue } from '@/type-utils';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import type { DTOOfModel } from './DTOOfModel';
import type { InterfaceOfModel } from './InterfaceOfModel';
import type { ModelConstructor } from './ModelConstructor';

/**
 * The Model class is the base of all of the project's models.
 * Models are entities which represent a logical business unit for a given domain in the form of objects.
 */
export default abstract class Model<T extends DTO<unknown>> {
  /**
   * Constructs a new model from a DTO.
   * @param dto - A DTO which represents this model.
   * @throws If the constructor is passed a model instance rather than a DTO.
   */
  public constructor(dto: T) {
    // If the model is being constructed with an existing model, throw an error suggesting
    // using the static `.from()` method instead.
    if (dto instanceof this.constructor) {
      throw new InvalidArgumentError(
        `Models should only be passed DTOs when using \`new\`. ` +
          `Model "${this.constructor.name}" was passed a model instance instead. ` +
          `If you need to instantiate a model from a possible existing model instance, ` +
          `use the static \`.from()\` method instead. ` +
          `Example: \`${this.constructor.name}.from(modelOrDTO);\``
      );
    }
    // If not, let the derived constructor(s) initialize this model.
  }

  /**
   * Used to ensure that an object that might be either a model or a dto of a model
   * is indeed a model. If it is passed an existing model instance, that model
   * instance is returned. If it is passed a DTO for this model type, a new instance
   * of this model is returned. This allows us to pass models or DTOs that match an
   * interface around freely without needing to worry if they're actually models
   * until we need them to be.
   * @param this - Establishes that `this` is the model's constructor.
   * @param dto - An object that is possibly the an existing model instance or a DTO
   * representation of a model.
   * @returns Either a new model instance, or an existing instance if one was passed.
   * @throws When this method is called on the abstract class `Model` instead of a
   * subclass of `Model`.
   * @example ```
   * const SomeComponent = ({ lineItem }: { lineItem: ILineItem }) => {
   *   const liModel = LineItemModel.from(lineItem);
   *   // Call a method on the model.
   *   liModel.remove();
   * }
   * ```
   */
  public static from<T extends ModelConstructor<any> = typeof this>(
    this: T,
    dto:
      | InstanceType<T>
      | DTOOfModel<InstanceType<T>>
      | InterfaceOfModel<InstanceType<T>>
  ): InstanceType<T> {
    // If this is being called on the abstract `Model` constructor, throw.
    if ((this as unknown) === Model) {
      throw new Error(
        '`Model.from` is abstract. Only call this method on a subclass of `Model`'
      );
    }
    // If the passed argument is an instance of `this` constructor, it must be a model already.
    if (dto instanceof this) {
      return dto;
    }

    /* eslint-disable @typescript-eslint/no-unsafe-return --
    ES Lint does not know that `this` is a reference to the current constructor. */
    // @ts-expect-error -- TS complains that this is abstract, but we've already ensured it is not.
    return new this(dto);
    /* eslint-enable @typescript-eslint/no-unsafe-return */
  }

  /**
   * Updates the model with new data in a DTO representation.
   * @param dto - The DTO with the new data.
   */
  public update?(dto: Partial<T>): void;

  /**
   * Called implicitly in `JSON.stringify()`.
   * Converts the model to a DTO representation when serialized to JSON.
   * @returns The DTO representation of the model.
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#tojson_behavior toJSON() behavior}
   */
  public toJSON(): JSONValue<true> {
    return this.toDTO();
  }

  /** Creates a DTO representation of this Model. */
  public abstract toDTO(): T;

  /**
   * Used to ensure that an object that might be either a model or a dto of a model
   * is indeed a DTO representation. If it is passed an existing model instance, that
   * model's `toDTO` instance method will be called to return the DTO representation.
   * If it is not passed a model instance, it is assumed that the passed in argument is
   * then a DTO. This allows us to pass models or DTOs that match an
   * interface around freely without needing to worry if they're actually DTO and not models
   * until we need them to be.
   * @param this - Establishes that `this` is the model's constructor.
   * @param dto - An object that is possibly the an existing model instance or a DTO
   * representation of a model.
   * @returns A DTO representation of the model, if the object is a mode, otherwise the
   * same DTO that was passed in.
   * @throws When this method is called on the abstract class `Model` instead of a
   * subclass of `Model`.
   * @example ```
   * const SomeComponent = ({ lineItem }: { lineItem: ILineItem }) => {
   *   // Ensures that `lineItem` _will not_ be a model.
   *   const liModelDTO = LineItemModel.toDTO(lineItem);
   * }
   * ```
   */
  public static toDTO<T extends ModelConstructor<any> = typeof this>(
    this: T,
    dto:
      | InstanceType<T>
      | DTOOfModel<InstanceType<T>>
      | InterfaceOfModel<InstanceType<T>>
  ): DTOOfModel<InstanceType<T>> {
    // If this is being called on the abstract `Model` constructor, throw.
    if ((this as unknown) === Model) {
      throw new Error(
        '`Model.from` is abstract. Only call this method on a subclass of `Model`'
      );
    }
    // If the passed argument is an instance of `this` constructor, it must be a model
    // already. Since we want an DTO, call the instance method `toDTO`.
    if (dto instanceof this) {
      return dto.toDTO() as DTOOfModel<InstanceType<T>>;
    }

    /* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access,
      @typescript-eslint/no-unsafe-call -- ES Lint does not know that `this` is a reference to the current constructor. */
    // Else, forcibly create a new instance of this model and call `toDTO` on it.
    // @ts-expect-error -- TS complains that `this` is an abstract `Model`, but we've already ensured it is not.
    return new this(dto).toDTO() as DTOOfModel<InstanceType<T>>;
    /* eslint-enable @typescript-eslint/no-unsafe-return */
  }
}
