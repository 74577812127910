import config from './config';

const ui = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Global UI settings. These may be shared by multiple pages. */
      global: {
        /**
         * Whether to hide uncaught errors from the user.
         *
         * An uncaught error is an error that occurs outside of rendering, such as in an effect or event handler,
         * and is not wrapped in a `try`/`catch` block. Such an error will **not** bubble up the component tree,
         * but instead will be intercepted by our global event listeners attached in `GlobalErrorAlertProvider`.
         * If `hideUncaughtErrors` is `true`, the `GlobalErrorAlertProvider` will not display the error to the user.
         */
        hideUncaughtErrors: false,
        /**
         * Whether to hide render errors from the user.
         *
         * A render error is an uncaught error that occurs during the rendering of a component (i.e. not in an effect or event handler).
         * Such an error will bubble to the top of the component tree and be caught by the `ErrorBoundary`.
         * If `hideRenderErrors` is `true`, the `ErrorBoundary` will not display the error to the user.
         *
         * TODO: This config is a stopgap for launch. We'll want to redesign our
         * ErrorBoundary to handle render errors more gracefully.
         */
        hideRenderErrors: false
      },

      /** Product Display Page UI settings. */
      pdp: {
        /** UI settings for the product description. */
        description: {
          /** Whether to truncate the product name. */
          truncateName: false
        }
      },

      video: {
        /**
         * Whether or not to include a fullscreen icon.
         */
        showFullscreenIcon: false,
        /**
         * Whether or not to display playback progress.
         */
        showPlaybackProgress: false
      }
    }
  },
  'AHNU-US': {
    default: {
      global: {
        hideUncaughtErrors: {
          default: false,
          prod: true
        },
        hideRenderErrors: {
          default: false,
          prod: true
        }
      },

      pdp: {
        description: {
          truncateName: true
        }
      },

      video: {
        showFullscreenIcon: true,
        showPlaybackProgress: true
      }
    }
  }
});

export default ui;
