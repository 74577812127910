import config from './config';

const session = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Determines if session should be cached by the Next server. */
      enableCache: false
    }
  }
});

export default session;
