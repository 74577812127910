'use client';

// Whatever this gets used on needs to be a client component since hooks can
// only be used in client components. Therefore, hooks can only be stored in
// HooksExecuter if done so from a client component.

/**
 * @file Much of the code of this file is borrowed the below link.
 * @see https://github.com/naycho334/react-hooks-outside/blob/main/src/index.js
 */

/* eslint-disable @typescript-eslint/ban-types -- allow `Function` type since any
hook function is allowed. */

import { ResourceNotFoundError } from '@/utils/errors/ResourceNotFoundError';
import { FunctionComponent } from 'react';

/**
 * The `HooksExecuter` class is designed to allow executing hooks from outside of a
 * React component by proxying the hook call through an "empty" React component.
 */
class HooksExecuter {
  private hooks: Record<string, { name: string; hook: (...args: any) => any }> =
    {};

  private temp: Record<string, unknown> = {};

  /** @inheritdoc */
  public constructor() {
    this.setHook = this.setHook.bind(this);
    this.getHook = this.getHook.bind(this);
    this.putHooks = this.putHooks.bind(this);
  }

  /**
   * Add a hook to the hook executor instance.
   * @param name - The name of the hook.
   * @param hook - The hook function itself.
   * @returns This `HookExecuter` instance for chaining method calls.
   */
  public setHook<T extends (...args: any) => any>(name: string, hook: T): this {
    [
      { value: name, id: 'name', type: 'string' },
      { value: hook, id: 'hook', type: 'function' }
    ].forEach(({ value, id, type }) => {
      if (typeof value !== type)
        throw new TypeError(`"${id}" expected to be of type ${type}`);
    });

    this.hooks[name] = { name, hook };
    return this;
  }

  /**
   * Accepts the result of a executing a hook.
   * @param name - The name of the hook which was executed.
   * @param result - The result hook function itself.
   */
  private putHooks(name: string, result: any): void {
    this.temp[name] = result;
  }

  /**
   * Creates an "empty" component. This component is used to execute the registered
   * hooks against.
   * @returns An "empty" component to execute the hooks against.
   */
  public component(): FunctionComponent {
    /* eslint-disable-next-line @typescript-eslint/no-this-alias -- needed to pass to
     * "empty" component. */
    const self = this;
    const EmptyComponent: FunctionComponent = () => {
      Object.values(self.hooks).forEach(({ name, hook }) =>
        self.putHooks(name, hook())
      );
      /* eslint-disable-next-line react/jsx-no-useless-fragment -- Used to create an
       * "empty" component. */
      return <></>;
    };

    return EmptyComponent;
  }

  /**
   * Get a previously registered hook.
   * @param name - The string name of the hook.
   * @returns The result of the hook for the given string name.
   * @throws A {@link ResourceNotFoundError} when the hook is not known.
   */
  public getHook<T>(name: string): T {
    const hookResult = this.temp[name];
    if (!hookResult) {
      throw new ResourceNotFoundError(
        `Hook "${name}" was not previously registered and could not be found.`
      );
    }
    return hookResult as T;
  }

  /**
   * Tries to get a possibly registered hook. If `undefined` is returned, you must
   * handle the return value appropriately.
   * @param name - The string name of the hook.
   * @returns The result of the hook for the given string name or `undefined`.
   */
  public tryGetHook<T>(name: string): T | undefined {
    try {
      return this.getHook<T>(name);
    } catch (e) {
      return undefined;
    }
  }
}

// new instance
const instance = new HooksExecuter();

// component
export const ReactHooksWrapper = instance.component();

// methods
export const tryGetHook = instance.tryGetHook.bind(instance);
export const getHook = instance.getHook.bind(instance);
export const setHook = instance.setHook.bind(instance);
