import { InstanceTypeOf } from '@/type-utils';
import { errorFactory } from '../error-utils';
import { ResourceNotFoundError } from './ResourceNotFoundError';

export const { InvalidPathError } = errorFactory(
  'InvalidPathError',
  ResourceNotFoundError
);

/** Error to use when a path to a resource is invalid. */
export type InvalidPathError = InstanceTypeOf<typeof InvalidPathError>;
