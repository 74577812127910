import config from './config';

const personalization = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      tests: {
        /**
         * An AB test to determine whether the sticky header should be enabled.
         */
        stickyHeaderABTestEnabled: false
      }
    }
  },
  'AHNU-US': {
    default: {
      tests: {
        stickyHeaderABTestEnabled: true
      }
    }
  }
});

export default personalization;
