import config from '../config';

const elasticPath = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      baseURL: 'https://epcc-integration.global.ssl.fastly.net',
      clientId: 'AVGfrH1HgKxfIeDBG0AFAU3LBDX3kjZZBsrwtfXQHa'
    }
  }
});

export default elasticPath;
