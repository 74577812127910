import config from '../config';
import { COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID } from '../env/server';

/** Configuration for the Cognito integration service. */
const cognito = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * User Pool ID. From the docs:
       * > A user pool is a user directory in Amazon Cognito.
       * > With a user pool, your users can sign in to your web or mobile app
       * > through Amazon Cognito, or federate through a third-party
       * > identity provider (IdP). Whether your users sign in directly or
       * > through a third party, all members of the user pool have a
       * > directory profile that you can access through an SDK.
       *
       * @see [What is Amazon Cognito? - AWS Docs](https://docs.aws.amazon.com/cognito/latest/developerguide/what-is-amazon-cognito.html)
       */
      userPoolID: {
        /** The User Pool ID for the end users - i.e. The customers. */
        endUserPool: {
          // The user pool ID may be defined separately for each environment or it may
          // fallback to the default value. This is done for ease of switching between
          // environments during development and debugging, and allowing easier migration
          // when deploying to a new environment.
          // dev: 'us-west-2_dDqSfE1aV', // The Cognito user pool ID for the DEV environment.
          // qa: 'us-west-2_ZGVsOs5Js', // The Cognito user pool ID for the DEV environment.
          // uat: 'us-west-2_3S6AgpdZr', // The Cognito user pool ID for the DEV environment.

          // For stability, we use the UAT user pool ID for all environments except production.
          default: 'us-west-2_3S6AgpdZr',
          dev: 'us-west-2_ZGVsOs5Js',
          prod: 'us-west-2_nV91hRwFX'
        },
        /** The User Pool ID for the BFF (backend-for-frontend) to perform server-side tasks. */
        superUserPool: COGNITO_USER_POOL_ID ?? ''
      },

      /**
       * App Client ID. From the docs:
       * > An app is an entity within a user pool that has permission to call
       * > unauthenticated API operations. Unauthenticated API operations are
       * > those that do not have an authenticated user. Examples include
       * > operations to register, sign in, and handle forgotten passwords.
       * >
       * > To call these API operations, you need an app client ID and an
       * > optional client secret. It is your responsibility to secure any
       * > app client IDs or secrets so that only authorized client apps can
       * > call these unauthenticated operations.
       *
       * @see [Configuring a user pool app client - AWS Docs](https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-client-apps.html)
       */
      clientID: {
        /** The Client ID for the end users - i.e. The customers. */
        endUserClient: {
          // The end user client auth key may be defined separately for each environment or it may
          // fallback to the default value. This is done for ease of switching between
          // environments during development and debugging, and allowing easier migration
          // when deploying to a new environment.
          // dev: '4bjll72rnuqj9getgbkuf1rbrn', // The Cognito client auth key for the DEV environment.
          // qa: '266hb6d641lifspsp771cu9jj3', // The Cognito client auth key for the QA environment.
          // uat: '41e97n2ki22dfvcnfiafcr2348', // The Cognito client auth key for the UAT environment.

          // For stability, we use the UAT Client auth key for all environments except production.
          default: '41e97n2ki22dfvcnfiafcr2348',
          dev: '4bjll72rnuqj9getgbkuf1rbrn',
          prod: '5rua9iibdl1gq43uq9uv6j8ic5'
        },
        /** The Client ID for the BFF (backend-for-frontend) to perform server-side tasks. */
        superUserClient: COGNITO_CLIENT_ID ?? ''
      }
    }
  },
  'AHNU-US': {
    default: {
      userPoolID: {
        endUserPool: {
          // dev: 'us-west-2_JfMp6vbib', // The Cognito user pool ID for the DEV environment.
          // qa: 'us-west-2_NxzSBuX0b', // The Cognito user pool ID for the QA environment.
          // uat: 'us-west-2_ByEjwaYZR', // The Cognito user pool ID for the UAT environment.

          // For stability, we use the UAT user pool ID for all environments except production.
          default: 'us-west-2_ByEjwaYZR',
          dev: 'us-west-2_JfMp6vbib',
          qa: 'us-west-2_NxzSBuX0b',
          prod: 'us-west-2_qfazH8q2Q'
        }
      },
      clientID: {
        endUserClient: {
          // dev: '35n8nuudiq5fv5av4sjq6ae53d', // The Cognito client auth key for the DEV environment.
          // qa: '2rgl977s73lae2a1m6n0cidma9', // The Cognito client auth key for the QA environment.
          // uat: '7bngup1jko9pj198e24enkgnvi', // The Cognito client auth key for the UAT environment.

          // For stability, we use the UAT Client auth ID for all environments except production.
          default: '7bngup1jko9pj198e24enkgnvi',
          dev: '35n8nuudiq5fv5av4sjq6ae53d',
          qa: '2rgl977s73lae2a1m6n0cidma9',
          prod: '1j4etfi35h3dhi7spmrci9o11s'
        }
      }
    }
  }
});

export default cognito;
