import config from './config';

/** Setting for the affiliate program page.  */
const blog = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Whether or not to display related posts in the post content page: /blog/:slug.
       */
      canDisplayRelatedPosts: true,
      /**
       * The categories that are available for blog posts.
       */
      categories: [
        {
          id: 'travel',
          label: 'Travel'
        },
        { id: 'impact', label: 'Impact' },
        { id: 'community', label: 'Community' },
        { id: 'lifestyle', label: 'Lifestyle' },
        { id: 'news', label: 'News' },
        { id: 'uncategorized', label: 'Others' }
      ],
      /**
       * Whether or not to display the overlay on the blog post card.
       */
      canDisplayOverlay: true,
      /**
       * Whether or not to display the short content on the blog post card.
       */
      canDisplayShortContent: false
    }
  },
  'TEVA-US': {
    default: {
      canDisplayRelatedPosts: false,
      categories: [
        { id: 'expression', label: 'Expression' },
        { id: 'activism', label: 'Activism' },
        { id: 'adventure', label: 'Adventure' },
        { id: 'lifestyle', label: 'Lifestyle' }
      ],
      canDisplayOverlay: false,
      canDisplayShortContent: true
    }
  }
});

export default blog;
