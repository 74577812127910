import config from './config';

const order = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Settings related to order history. */
      orderHistory: {
        /**
         * The amount of items to show per page in order histories.
         * A value of 7 was causing timeouts in AWS.
         */
        pageSize: 3
      },

      /**
       * Shows the cancel order button during certain order statuses.
       */
      showCancelOrder: false
    }
  }
});

export default order;
