import config from '../config';

/**
 * The following config denotes the values that are included in the osano cookie consent
 * banner {@link ../../../react/src/components/cookie-preferences/OsanoScript/index.tsx}.
 */
const osano = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      enabled: false,
      /**
       * This is generated by the Osano application when you create your osano.js.
       * It is the first string of numbers in your javascript and will be unique to your organization.
       * All configurations (brands) will have the same user/customer_id.
       */
      customerID: 'AzZqZ0TY1pNXG1Bb3',
      /**
       * This is generated by the Osano application when you create the osano.js script.
       * It is the second string of numbers in your javascript and will be unique
       * to each configuration you create.
       */
      configurationID: undefined as unknown as string
    }
  },
  'AHNU-US': {
    default: {
      enabled: true,
      configurationID: 'e973be80-d9d8-43f6-9daf-d17a0e4c3b69'
    }
  },
  'TEVA-US': {
    default: {
      enabled: true,
      configurationID: '9a8d4b4f-eb68-473c-9e18-595255ae8440'
    }
  },
  'HOKA-US': {
    default: {
      enabled: true,
      // TODO: Add the correct configuration ID for Hoka - This is Teva's ID.
      configurationID: '9a8d4b4f-eb68-473c-9e18-595255ae8440'
    }
  },
  'UGG-US': {
    default: {
      enabled: true,
      // TODO: Add the correct configuration ID for UGG -This is Teva's ID.
      configurationID: '9a8d4b4f-eb68-473c-9e18-595255ae8440'
    }
  },
  'UGG-COLLAB-US': {
    default: {
      enabled: true,
      // TODO: Add the correct configuration ID for UGG-COLLAB - This is Teva's ID.
      configurationID: '9a8d4b4f-eb68-473c-9e18-595255ae8440'
    }
  },
  'KOOLABURRA-US': {
    default: {
      enabled: true,
      // TODO: Add the correct configuration ID for KOOLABURRA - This is Teva's ID.
      configurationID: '9a8d4b4f-eb68-473c-9e18-595255ae8440'
    }
  }
});

export default osano;
