import config from './config';

const giftCard = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Used to control whether we want to display gift card form or not in checkout flow.
       */
      isDisplayingCheckoutGiftCard: true,
      /** The number of characters replaced by '*' for applied gift cards. */
      numberOfMaskedCharacters: 15,

      /**
       * Used to control whether we want to display gift card purchase CTA in the Gift
       * Card Landing Page: /gift-cards.
       */
      isPurchaseAllowed: false,
      /**
       * Used to control whether we want to display the desktop title in the Gift Card
       * Landing Page: /giftcards.
       */
      canDisplayDesktopTitle: true
    }
  },
  'AHNU-US': {
    default: {
      canDisplayDesktopTitle: false
    }
  },
  'TEVA-US': {
    default: {
      isPurchaseAllowed: true
    }
  }
});

export default giftCard;
