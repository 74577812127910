import config from './config';

const socials = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      blog: '',
      facebook: 'https://www.facebook.com/SanukFootwear',
      instagram: 'https://www.instagram.com/sanuk/',
      pinterest: 'https://www.pinterest.com/sanuk/',
      twitter: 'https://twitter.com/sanukfootwear',
      youtube: 'https://www.youtube.com/user/sanuk',
      strava: '',
      tiktok: ''
    }
  },
  'AHNU-US': {
    default: {
      blog: '',
      facebook: 'https://www.facebook.com/profile.php?id=100093981426923',
      instagram: 'https://www.instagram.com/ahnulifestyle/',
      pinterest: 'https://www.pinterest.com/ahnulifestyle/',
      twitter: '',
      youtube: 'https://www.youtube.com/@AHNUlifestyle'
    }
  },
  'TEVA-US': {
    default: {
      blog: '/blog',
      facebook: 'https://www.facebook.com/teva',
      instagram: 'https://www.instagram.com/teva/',
      pinterest: 'https://www.pinterest.com/teva/',
      twitter: 'https://twitter.com/teva',
      youtube: 'https://www.youtube.com/Teva'
    }
  },
  'UGG-COLLAB-US': {
    default: {
      facebook: 'https://www.facebook.com/ugg',
      instagram: 'https://www.instagram.com/ugg/',
      pinterest: 'https://www.pinterest.com/ugg/',
      twitter: 'https://twitter.com/ugg',
      youtube: 'https://www.youtube.com/c/ugg'
    }
  },
  'UGG-US': {
    default: {
      facebook: 'https://www.facebook.com/ugg',
      instagram: 'https://www.instagram.com/ugg/',
      pinterest: 'https://www.pinterest.com/ugg/',
      twitter: 'https://twitter.com/ugg',
      youtube: 'https://www.youtube.com/c/ugg'
    }
  },
  'KOOLABURRA-US': {
    default: {
      instagram: 'https://www.instagram.com/koolaburra/',
      pinterest: 'https://www.pinterest.com/koolaburra/',
      facebook: 'https://www.facebook.com/koolaburra/',
      tiktok: 'https://www.tiktok.com/@koolaburrabyugg'
    }
  },
  'HOKA-US': {
    default: {
      facebook: 'https://www.facebook.com/hoka',
      instagram: 'https://www.instagram.com/hoka/',
      twitter: 'https://twitter.com/hoka',
      youtube: 'https://www.youtube.com/c/hokaoneone',
      strava: 'https://www.strava.com/clubs/hoka-76016',
      pinterest: 'https://www.pinterest.com/hokaofficial/',
      tiktok: 'https://www.tiktok.com/@hoka'
    }
  }
});

export default socials;
