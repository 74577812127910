import { local } from '@/configs';
import { Constructor, JSONValue } from '@/type-utils';
import type Service from '../../Service';
import { EnvironmentService } from '../EnvironmentService';

import { MockState } from './MockState';
import type { ServiceMock } from './ServiceMock';
import type { MockedMethods } from './types/MockedMethods';
import type { MockedProperties } from './types/MockedProperties';

/**
 * The registry entry that will be used to store the state
 * of all the mocks.
 */
export interface IMockRegistryEntry<
  T extends Service,
  Mock extends ServiceMock<T>
> {
  /** Is the mock currently enabled. Checks the mocked JSON for initial value. */
  enabled: boolean;
  /** The service being mocked. */
  service: Service;
  /** The methods available for mocking on the service. */
  mockedMethods: MockedMethods<T>;
  /** The properties available to be mocked on the service. */
  mockedProperties: MockedProperties<T, Mock>;
  /** The state to overwrite on the service mock. */
  mockedState: MockState;
}

/**
 * Mock service is a centralized service through which we can dynamically interact
 * with other services to control when and how they're mocked.
 */
class MockService<T extends Service, Mock extends ServiceMock<T>> {
  /**
   * The registry of all currently registered mocks. These could be any mocked value,
   * anything that we may want to create a "default" mock for. Service mocks may also
   * live in here. The value for this could be obtained from a separate file that
   * houses this object as well. Like we do with the global middlewares.
   */
  private _mockRegistry: Record<string, IMockRegistryEntry<T, Mock>> = {};

  /**
   * The registry of all currently registered mocks. These could be any mocked value,
   * anything that we may want to create a "default" mock for. Service mocks may also
   * live in here. The value for this could be obtained from a separate file that
   * houses this object as well. Like we do with the global middlewares.
   */
  public get mockRegistry(): Record<string, IMockRegistryEntry<T, Mock>> {
    return this._mockRegistry;
  }

  /**
   * Allows you to get the name of the service or the constructor. If there is a name on
   * the object then it is assumed to be a Service, if not, then it is assumed to be
   * a constructor. The constructor object will exist in both cases.
   * @param service - The service, this could be the service constructor or the
   * instantiated service.
   * @returns A string used to store or retrieve the mock in the registry.
   */
  private getNameFromService(service: T | Constructor<T>): string {
    if ('name' in service) {
      return (service as Constructor<T>).name;
    }

    return service.constructor.name;
  }

  /**
   * Given a value, this method will return whether or not the value represents
   * that the service should be enabled. For booleans, the decision is obvious, but for
   * special values such as `local`, the decision is based on the current environment. In
   * the case of `local`, the service is only enabled if we're in a local development environment.
   * @param value - The value to check.
   * @returns Whether the value represents that the service should be enabled.
   */
  private valueRepresentsEnabled(value: boolean | typeof local): boolean {
    // If the value is the special `local` value, then we need to check the environment.
    if (value === local) {
      // If we're not running within our remote host - Vercel - and we're not running
      // within a CI environment, then we'll consider the current environment to be local development.
      return !process.env.VERCEL && !process.env.CI;
    }

    // If the value is a boolean, then we can just return it directly.
    return value;
  }

  /**
   * This method registers the mock for a service. You pass the service you're
   * mocking, an object represent the mocks for methods of the service, and an object
   * represents the mocks of the public properties and accessors for a service.
   * @param enabled - Sets the initial enabled value. This may be `true`, `false`, or a
   * special contextual value such as `local` - which will only enable the mock if we're
   * in a local development environment, for instance.
   * @param service - The Service itself, could be a constructor.
   * @param mockedMethods - The methods on the service with their new mocks.
   * @param mockedProperties - Public properties on the service.
   * @param mockedState - The state to add to the new service mock.
   *
   * @todo Fix types so that this service is no longer generic, and this
   * method no longer extends T but rather Service directly.
   */
  public mockService<U extends T, V extends Record<never, JSONValue> = any>(
    enabled: boolean | typeof local,
    service: U | Constructor<U>,
    mockedMethods: MockedMethods<U>,
    mockedProperties: MockedProperties<U, ServiceMock<U, V>>,
    mockedState: MockState<V>
  ): void {
    const name = this.getNameFromService(service);
    this._mockRegistry[name] = {
      // Check if the service should be enabled based on the passed in value. This handles
      // special cases such as `local` which only enables the mock if we're in a local
      // development environment.
      enabled: this.valueRepresentsEnabled(enabled),

      service,
      mockedMethods,
      mockedProperties,
      mockedState
    } as unknown as IMockRegistryEntry<T, Mock>;
  }

  /**
   * Is the given service mock enabled or not.
   * @param service - The service mock which is enabled or not.
   * @returns Whether the service mock is enabled or not.
   */
  public isMockEnabled(service: T | Constructor<T>): boolean {
    // If we're not in production, check the registry for whether this mock should be
    // enabled or not.
    if (!(process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
      const name = this.getNameFromService(service);
      return this.mockRegistry[name].enabled;
    }

    // Otherwise, if we are in production, we should never enable the mock.
    return false;
  }

  /**
   * Tells the application to definitely enable mocking of a registered service. This
   * should do nothing in production.
   * @param service - The service to be enabled.
   */
  public enableMockingFor(service: T | Constructor<T>): void {
    if (!(process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
      const name = this.getNameFromService(service);
      this.mockRegistry[name].enabled = true;
    }
  }

  /**
   * Tells the application to definitely disable mocking of a registered service.
   * This also should do nothing in production.
   * @param service - The service to be disabled.
   */
  public disableMockingFor(service: T | Constructor<T>): void {
    if (!(process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
      const name = this.getNameFromService(service);
      this.mockRegistry[name].enabled = false;
    }
  }

  /**
   * Gets the proxy of the current mock.
   * @param service - The underlying service for the mock.
   * @returns - The proxy mock built off the registry entry.
   */
  public getMockOf(service: T | Constructor<T>): T {
    const name = this.getNameFromService(service);
    const entry = this.getMockEntry(name);
    const publicMembers = {
      ...entry.mockedMethods,
      ...entry.mockedProperties,
      state: entry.mockedState,
      name
    };

    return new Proxy(publicMembers, {
      /**
       * If `prop` is a mocked property whose value is a function,
       * this returns the result of calling the function with the mock state.
       * Otherwise, the normal property value is returned.
       *
       * @param target - T.
       * @param prop - T.
       * @returns T.
       */
      get(
        target: typeof publicMembers,
        prop: keyof typeof publicMembers & (string | symbol)
      ): unknown {
        const propertyValue = target[prop] as unknown;
        if (
          prop in entry.mockedProperties &&
          typeof propertyValue === 'function'
        ) {
          return propertyValue.call(target, target.state);
        }

        return propertyValue;
      }
    }) as unknown as T;
  }

  /**
   * Provides the mock of anything that has been registered, by its key.
   * @param key - A key off the mockRegistry object.
   * @returns A mock from the registry.
   */
  public getMockEntry(
    key: keyof this['mockRegistry']
  ): IMockRegistryEntry<T, Mock> {
    return this.mockRegistry[key.toString()];
  }

  /**
   * Overrides the mocks for a service within the context of the `withMockedService`
   * callback. This allows us to temporary change how the mocks work for a service.
   * This is useful for testing automations. After the callback has finished, the
   * mock is returned to its previous state. Before the callback the mock state is
   * saved. After the callback the mock state is restored.
   * @param service - The service being mocked.
   * @param mockedMethods - The public methods with override mocks.
   * @param mockedProperties - Override public properties on the service.
   * @param mockedState - A new mockstate object for the service mock.
   * @param withMockedService - The underlying base mocked service.
   */
  public async overrideServiceMock(
    service: T | Constructor<T>,
    mockedMethods: MockedMethods<T>,
    mockedProperties: MockedProperties<T, Mock>,
    withMockedService: () => Promise<void>
  ): Promise<void> {
    const name = this.getNameFromService(service);
    const entry = this._mockRegistry[name];
    const currentState = {
      ...entry
    };

    const methods = { ...entry.mockedMethods, ...mockedMethods };
    const properties = { ...entry.mockedProperties, ...mockedProperties };

    this._mockRegistry[name] = {
      enabled: true,
      service,
      mockedMethods: methods,
      mockedProperties: properties,
      mockedState: entry.mockedState
    };

    await withMockedService();
    this._mockRegistry[name] = currentState;
  }
}

export default new MockService();
