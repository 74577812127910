import config from './config';

const routes = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Route middleware settings. */
      middleware: {
        /**
         * Sets the maximum amount of time in milliseconds
         * a middleware is allowed spend executing. This is
         * used to capture when middlewares are running
         * excessively long.
         */
        timeout: 700
      },
      /**
       * A flag to dictate whether the performance of middleware
       * and routes should be captured in some way.
       */
      measurePerformance: { default: false, dev: true }
    }
  }
});

export default routes;
