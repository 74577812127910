import config from '../config';
import {
  AWS_AUTH,
  AWS_AUTH_DEV,
  AWS_AUTH_QA,
  AWS_AUTH_UAT,
  AWS_AUTH_PROD
} from '../env/server';

/** General AWS configuration. */
const aws = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** API key to authenticate requests with. */
      auth: {
        // The authentication key may be defined separately for each environment or it may
        // fallback to the default value. This is done for ease of switching between
        // environments during development and debugging, and allowing easier migration
        // when deploying to a new environment.
        // dev: AWS_AUTH_DEV ?? AWS_AUTH, // The AWS Dev API Gateway Auth Key
        // qa: AWS_AUTH_QA ?? AWS_AUTH, // The AWS QA API Gateway Auth Key
        // uat: AWS_AUTH_UAT ?? AWS_AUTH, // The AWS UAT API Gateway Auth Key

        // For stability, we use the UAT API Gateway Auth Key for all environments except production.
        default: AWS_AUTH_UAT ?? AWS_AUTH,
        dev: AWS_AUTH_DEV ?? AWS_AUTH,
        qa: AWS_AUTH_QA ?? AWS_AUTH,
        prod: AWS_AUTH_PROD ?? AWS_AUTH
      },

      /** The base URL for the API Gateway. */
      gatewayURL: {
        // dev: 'https://dev.deca.deckers.com', // The AWS Dev API Gateway URL
        // qa: 'https://qa.deca.deckers.com', // The AWS QA API Gateway URL
        // uat: 'https://uat.deca.deckers.com', // The AWS UAT API Gateway URL

        // For stability, we use the UAT API Gateway URL for all environments except production.
        default: 'https://uat.api.deca.deckers.com',
        dev: 'https://dev.api.deca.deckers.com',
        qa: 'https://qa.api.deca.deckers.com',
        prod: 'https://prod.api.deca.deckers.com' // The AWS Prod API Gateway URL
      },

      /**
       * The current version used, the bleeding edge is `default`. The `default`
       * API Stage will use a different API Key than other versions.
       */
      version: 'dev'
    }
  }
});

export default aws;
