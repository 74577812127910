import config from './config';

/** The recommendation context enum. */
export enum RecommendationContext {
  HomePage = 'HomePage',
  PDP = 'PDP',
  Cart = 'Cart',
  Account = 'Account',
  NotFoundPage = 'NotFoundPage',
  AddToCart = 'AddToCart',
  Quickview = 'Quickview'
}

const recommendations = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      homepage: {
        context: RecommendationContext.HomePage,
        enabled: false,
        /**
         * By default, mobile recommendations will be slide,
         * and if set to false it will be a grid. Default limit
         * on recommendation tiles to show is 4.
         */
        isSlideOnMobile: false
      },
      pdp: {
        context: RecommendationContext.PDP,
        enabled: false,
        isSlideOnMobile: true
      },
      notFoundPage: {
        context: RecommendationContext.NotFoundPage,
        enabled: false,
        isSlideOnMobile: false
      },
      cart: {
        context: RecommendationContext.Cart,
        enabled: false,
        isSlideOnMobile: false
      },
      account: {
        context: RecommendationContext.Account,
        enabled: false,
        isSlideOnMobile: false
      },
      addToCart: {
        context: RecommendationContext.AddToCart,
        enabled: false,
        isSlideOnMobile: false
      },
      quickview: {
        context: RecommendationContext.Quickview,
        enabled: false,
        isSlideOnMobile: false
      }
    }
  },
  'SANUK-US': {
    default: {
      homepage: {
        context: RecommendationContext.HomePage,
        enabled: false,
        isSlideOnMobile: false
      },
      pdp: {
        context: RecommendationContext.PDP,
        enabled: true,
        isSlideOnMobile: true
      },
      notFoundPage: {
        context: RecommendationContext.NotFoundPage,
        enabled: true,
        isSlideOnMobile: false
      },
      cart: {
        context: RecommendationContext.Cart,
        enabled: true,
        isSlideOnMobile: false
      },
      account: {
        context: RecommendationContext.Account,
        enabled: false,
        isSlideOnMobile: false
      },
      addToCart: {
        context: RecommendationContext.AddToCart,
        enabled: true,
        isSlideOnMobile: false
      },
      quickview: {
        context: RecommendationContext.Quickview,
        enabled: true,
        isSlideOnMobile: false
      }
    }
  },
  'AHNU-US': {
    default: {
      homepage: {
        context: RecommendationContext.HomePage,
        enabled: false,
        isSlideOnMobile: false
      },
      pdp: {
        context: RecommendationContext.PDP,
        enabled: false,
        isSlideOnMobile: true
      },
      notFoundPage: {
        context: RecommendationContext.NotFoundPage,
        enabled: false,
        isSlideOnMobile: false
      },
      cart: {
        context: RecommendationContext.Cart,
        enabled: false,
        isSlideOnMobile: false
      },
      account: {
        context: RecommendationContext.Account,
        enabled: false,
        isSlideOnMobile: false
      },
      addToCart: {
        context: RecommendationContext.AddToCart,
        enabled: false,
        isSlideOnMobile: false
      },
      quickview: {
        context: RecommendationContext.Quickview,
        enabled: false,
        isSlideOnMobile: false
      }
    }
  }
});

export default recommendations;
