import config from './config';

const cookies = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Log a warning for cookies that are longer than this.
       * 50% of the maximum cookie length (4096 bytes). Even though
       * cookies of this size will be accepted by browsers, it's still
       * worth taking a look at.
       */
      warnThreshold: 2048,

      /**
       * Throw an error for cookies that are longer than this.
       * Maximum cookie length of 4096 bytes. Includes the whole string
       * (name, value, maxAge, expiry, domain, path, ...).
       * Strings larger than this will not be accepted for most browsers, or
       * lead to unrealiable behavior.
       */
      errorThreshold: 4096
    }
  }
});

export default cookies;
