import config from './config';

const search = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      enableSearch: true,
      enableSearchAnalytics: true
    }
  },
  'AHNU-US': {
    default: {
      enableSearch: false
    }
  }
});

export default search;
