import { FORTER_API_KEY } from '../env/server';

import config from '../config';

const forter = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * The ID assigned to the site by Forter.
       */
      siteId: {
        default: 'b9e00c128ac1', // Sandbox environment,
        prod: '2bfea60f3843' // Live environment
      },
      /**
       * A secret key used by Forter for Basic Authorization.
       */
      secretKey: FORTER_API_KEY as string,
      /**
       * The base URL used for Forter's API.
       */
      baseURL: 'https://api.forter-secure.com',
      /**
       * Whether the API is currently enabled.
       */
      enabled: true,
      /**
       * The name of the cookie that the Forter token is written to.
       * This is used to implement Forter's Token Trigger Integration (TTI),
       * as well as help keep the name consistent between the front and back-end.
       */
      tokenCookieName: 'deckers-forter-token',
      /**
       * The version of the Forter API being used.
       */
      apiVersion: '2.11',
      /**
       * The name of the merchant. This is used if a merchant operates several sites
       * (such as a regular site and a related discount brand, or different locales).
       */
      merchantName: undefined as unknown as string // must be overridden by site
    }
  },
  'UGG-US': {
    default: {
      merchantName: 'UGG (US)'
    }
  },
  'KOOLABURRA-US': {
    default: {
      merchantName: 'KOOLABURRA (US)'
    }
  },
  'TEVA-US': {
    default: {
      merchantName: 'TEVA (US)'
    }
  },
  'HOKA-US': {
    default: {
      merchantName: 'HOKA (US)'
    }
  },
  'AHNU-US': {
    default: {
      merchantName: 'AHNU (US)'
    }
  }
});

export default forter;
