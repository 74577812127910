/**
 * Enumerates all possible shipping methods.
 *
 *  **IMPORTANT**
 * This enum only exists to give devs additional information about the
 * hardcoded shipping methods in
 * `ShippingMethodService.getApplicableShippingMethodIDsForCart`.
 *
 * Therefore, it should be removed once Conductor's rules engine is in place.
 */
enum ShippingMethod {
  /**
   * **Standard Ground Shipping**.
   *
   * Delivery time: 3-6 business days.
   *
   * Does not apply for carts in which all items are discounted.
   * In those cases, {@link ShippingMethod.STG2 STG2} applies instead.
   */
  STG = 'STG',

  /**
   * **Standard Ground Shipping (Special Case)**.
   *
   * Delivery time: 3-6 business days.
   *
   * Functionally equal to STG, but applies only for carts in which all items
   * are discounted. In any other case, {@link ShippingMethod.STG STG} applies instead.
   *
   * Meant for accounting purposes.
   */
  STG2 = 'STG2',

  /**
   * **Expedited Shipping**.
   *
   * Delivery time: 2-3 business days.
   *
   * Available for all carts, except when the `shipToAddress` is a PO Box or
   * outside the country.
   */
  ST2 = 'ST2',

  /**
   * **Fastest Shipping**.
   *
   * Delivery time: 1-2 business days.
   *
   * Available for all carts, except when the `shipToAddress` is a PO Box or
   * outside the country.
   */
  STO = 'STO',

  /**
   * **USPS Ground**.
   *
   * Delivery time is not disclosed.
   *
   * The only method available when the `shipToAddress` is a PO Box.
   */
  USPSGround = 'USPS Ground',

  /**
   * **SFS International**.
   *
   * Delivery time is not disclosed.
   *
   * The only method available when the `shipToAddress` is outside of the
   * country.
   */
  SFSIntl = 'SFS Standard International delivery'
}

export default ShippingMethod;
