import config from '../config';

const locally = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** The root URL to the Locally map widget script. Must be passed parameters specified below. */
      api: 'https://sanuk.locally.com/stores/map.js',
      /** The brand name identifier to pass to the Locally map widget script. */
      companyName: 'Sanuk',
      /** The numeric brand identifier to pass to Locally map widget script. */
      companyId: '97',
      /** Whether to inline the `iframe` Locally generates. Passed to the Locally map widget script. */
      inline: '1',
      /**
       * The lowercase BCP 47 language code. Used to identify the language and locale for Locally.
       * @see https://en.wikipedia.org/wiki/IETF_language_tag
       */
      lang: 'en-us'
    }
  },
  'TEVA-US': {
    default: {
      api: 'https://teva.locally.com/stores/map.js',
      companyName: 'Teva',
      companyId: '41448'
    }
  }
});

export default locally;
