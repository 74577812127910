/**
 * Enumerates valid **Card Networks**: Organizations that facilitate payment
 * card transactions. Also referred to as Card Types or Brands.
 *
 * Card Networks regulate who, where, and how cards are used.
 *
 * @example
 * - CardNetwork.Visa // 'Visa'
 * - CardNetwork.MasterCard // 'MasterCard'
 * - CardNetwork.AmericanExpress // 'AmericanExpress'
 * - CardNetwork.DiscoverCard // 'DiscoverCard'
 *
 * @see [Card Associations or Card Networks | Midigator](https://midigator.com/glossary/card-associations-or-card-networks/)
 */
export enum CardNetwork {
  /** Represents Visa® as a card network. */
  Visa = 'Visa',

  /** Represents Mastercard® as a card network. */
  MasterCard = 'MasterCard',

  /** Represents American Express® as a card network. */
  AmericanExpress = 'AmericanExpress',

  /** Represents China UnionPay® as a card network. */
  UnionPay = 'UnionPay',

  /** Represents JCB® as a card network. */
  JCB = 'JCB',

  /** Represents Diners Club® International as a card network. */
  DinersClubInternational = 'DinersClubInternational',

  /** Represents Diners Club® US and Canada as a card network. */
  DinersClubUSandCanada = 'DinersClubUSandCanada',

  /** Represents Discover® as a card network. */
  DiscoverCard = 'DiscoverCard',

  /** Represents Maestro as a card network. */
  Maestro = 'Maestro',

  /** Represents Solo as a card network. */
  SoloDebit = 'SoloDebit',

  /** Represents Switch as a card network. */
  SwitchDebit = 'SwitchDebit',

  /** Represents Visa Electron as a card network. */
  VisaElectron = 'VisaElectron',

  /** Represents enRoute as a card network. */
  enRoute = 'enRoute',

  /** Represents Klarna as a card network. */
  Klarna = 'Klarna',

  /**
   * Represents a generic, invalid card network.
   * Used exclusively for error handling; cannot be used for checking out.
   */
  Invalid = 'Invalid',

  /**
   * Represents an unknown card network.
   * Used exclusively for error handling; cannot be used for checking out.
   */
  Unknown = 'Unknown'
}

/**
 * Represents the members of {@link CardNetwork} that are _true_ card networks.
 * That is, any members that are neither "buy now, pay later" service providers
 * (i.e. {@link CardNetwork.Klarna Klarna}), nor are meant to be used internally for error handling
 * purposes.
 */
export type TrueCardNetwork = Exclude<
  CardNetwork,
  | CardNetwork.enRoute
  | CardNetwork.Klarna
  | CardNetwork.Invalid
  | CardNetwork.Unknown
>;
