import config from './config';

const tracking = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Whether to display tracking info on order shipment.
       * If true it will call tracking service from "trackingService" config.
       */
      enableShipmentTracking: true,
      /**
       * Tracking service.
       */
      trackingService: 'easypost'
    }
  }
});

export default tracking;
