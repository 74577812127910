import { OTEL_SERVICENAME } from '../env/public';
import config from '../config';

const otel = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Indicates whether or not the OTel service is active. */
      enabled: true,
      /** Name of APM service in DataDog. */
      serviceName: OTEL_SERVICENAME,
      /** The brand tag that will be passing in APM Span. */
      brand: undefined as unknown as string,
      /** The platform tag that will be passing in APM Span. */
      platform: 'vercel'
    }
  },
  'AHNU-US': {
    default: {
      brand: 'ahnu'
    }
  },
  'SANUK-US': {
    default: {
      brand: 'sanuk'
    }
  },
  'TEVA-US': {
    default: {
      brand: 'teva'
    }
  }
});

export default otel;
