import config from './config';

const returnAddress = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Return address. */
      address: {
        name: 'Teva Returns Department',
        street: '17791 N. Perris Blvd.',
        city: 'Moreno Valley',
        state: 'CA',
        zipCode: '92551'
      }
    }
  },
  'AHNU-US': {
    default: {
      address: {
        name: 'Ahnu Returns Department',
        street: '17791 N. Perris Blvd.',
        city: 'Moreno Valley',
        state: 'CA',
        zipCode: '92551'
      }
    }
  }
});

export default returnAddress;
