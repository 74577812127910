import config from '../config';

/** Magtek configuration. */
const magtek = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Whether Magtek features are enabled.
       * Setting this to `false` will prevent the following:
       * - Setting the agent cookie
       * - Placing Magtek orders.
       */
      enabled: {
        default: true,
        prod: false
      }
    }
  }
});

export default magtek;
