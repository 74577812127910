/**
 * Represents each of the four geographical regions that Deckers operates in.
 *
 * `NA` represents North America, `EMEA` represents Europe, the Middle East, and
 * Africa, `APAC` represents Asia-Pacific, and `LATAM` represents Latin America.
 *
 * Refer to the visualization below for a better understanding of each region:
 *
 * ![A graphic visualizing each region.](../.support/images/regions-explained.png "Regions Visualization").
 */
export enum Region {
  /**
   * Represents the North America region. This includes the United States and
   * Canada.
   */
  NA = 'NA',

  /**
   * Represents the Europe, Middle East, and Africa region.
   */
  EMEA = 'EMEA',

  /**
   * Represents the Asia-Pacific region. This includes Australia, New Zealand,
   * and Japan.
   */
  APAC = 'APAC',

  /**
   * Represents the Latin America region.
   *
   * **Note**: This region is not currently in use, but exists here for
   * completion and future-proofing. Furthermore, note that we _do_ ship to this
   * region, but _do not_ officially operate within it.
   */
  LATAM = 'LATAM'
}
