import config from './config';

/** Setting for the affiliate program page.  */
const affiliates = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Login URL for the affiliate program.
       */
      login: '',
      /**
       * Signup URL for the affiliate program.
       */
      signup: '',
      /**
       * Email link for the affiliate program.
       */
      email: 'mailto:rm-deckersoutdoor@mail.rakuten.com'
    }
  },
  'AHNU-US': {
    default: {
      login:
        'https://auth.rakutenmarketing.com/auth/realms/rakuten-advertising/protocol/openid-connect/auth?code_challenge_method=S256&client_id=publisher-dashboard-legacy&state=xjnje3bQmZrgYWKVZxprapHZ&response_mode=query&redirect_uri=https%3A%2F%2Fcli.linksynergy.com%2Fcli%2Fpublisher%2Fhome.php&response_type=code&nonce=F53A1hHMk13qdJ7Wa4ZdaJ-w&scope=openid&code_challenge=GopB7YLxNzMUhCOfP4L5kwt11nWDQoCtVzoce8W36ck',
      signup:
        'https://auth.rakutenmarketing.com/auth/realms/rakuten-advertising/protocol/openid-connect/registrations?client_id=publisher-dashboard&redirect_uri=https%3A%2F%2Fpublisher.rakutenadvertising.com&response_type=code',
      email: 'mailto:rm-deckersoutdoor@mail.rakuten.com'
    }
  },
  'TEVA-US': {
    default: {
      login:
        'https://auth.rakutenmarketing.com/auth/realms/rakuten-advertising/protocol/openid-connect/auth?code_challenge_method=S256&client_id=publisher-dashboard-legacy&state=xjnje3bQmZrgYWKVZxprapHZ&response_mode=query&redirect_uri=https%3A%2F%2Fcli.linksynergy.com%2Fcli%2Fpublisher%2Fhome.php&response_type=code&nonce=F53A1hHMk13qdJ7Wa4ZdaJ-w&scope=openid&code_challenge=GopB7YLxNzMUhCOfP4L5kwt11nWDQoCtVzoce8W36ck',
      signup:
        'https://auth.rakutenmarketing.com/auth/realms/rakuten-advertising/protocol/openid-connect/registrations?client_id=publisher-dashboard&redirect_uri=https%3A%2F%2Fpublisher.rakutenadvertising.com&response_type=code',
      email: 'mailto:rm-deckersoutdoor@mail.rakuten.com'
    }
  }
});

export default affiliates;
