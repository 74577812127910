import { mocked } from '@/configs';
import MockService, { ServiceMock } from './isomorphic/MockService';
import { Constructor } from '@/type-utils';
import { InvalidPathError } from '@/utils/errors';

declare const __isolatedContext__: boolean;

/**
 * A utility type for getting the unwrapped return type of a service method.
 */
export type ServiceResponse<
  T extends Service,
  K extends keyof T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Allow any function.
> = T[K] extends (...args: Array<any>) => any
  ? Awaited<ReturnType<T[K]>>
  : unknown;

/**
 * A utility type for getting the types of args of a service method.
 */
export type ServiceMethodArgs<
  T extends Service,
  K extends keyof T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Allow any function.
> = T[K] extends (...args: Array<any>) => any
  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Allow any set of parameters.
    Parameters<T[K]> extends [any, any, ...Array<any>]
    ? Parameters<T[K]>
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Allow any singular parameter.
    Parameters<T[K]> extends [any]
    ? Parameters<T[K]>[0]
    : never
  : never;

/**
 * Services are "black boxes" that perform a category of I/O operations. For these operations,
 * implementation does not matter to the consumer; only what the service produces for a given input.
 * Generally, services should be implemented as classes which contain methods related to the
 * business activity they’re designed to address.
 *
 * @see https://deckersbrand.atlassian.net/l/c/4hNLoG0a
 */
export default abstract class Service {
  /**
   * Constructs a new instance of the service which may be either the actual implementation or
   * a mocked version of the service.
   * @param this - Specifies that `this` will refer to a new service instance.
   * @param mock - A mock whose public interface matches that of this service.
   * @returns Either the actual service implementation or a mocked version thereof.
   * @throws If an error occurs while attempting to acquire the setting for a
   * service mock configuration.
   */
  public static withMock<T extends Service>(
    this: new () => T,
    mock: ServiceMock<T>
  ): T {
    // If we're in an isolated environment, always try use a mocked service instead
    // of a real one.
    if (typeof __isolatedContext__ !== 'undefined') {
      return mock.getMock();
    }

    const serviceName = this.name as unknown as keyof typeof mocked;

    // Represent the service name as a possible key in the `mocked` config file.
    try {
      // If the service is listed in the mocked JSON config and set to `true`
      if (MockService.isMockEnabled(this)) {
        // Use the mock.
        console.info(`Using mocked value for service "${serviceName}".`);

        return mock.getMock();
      }
    } catch (e) {
      // If the error thrown was that the path could not be found, fall-through.
      // Otherwise, re-throw. Normally we might use the `LoggerService` to log
      // an info-level log, but doing so would create a circular dependency in
      // this case.
      if (!(e instanceof InvalidPathError)) {
        throw e;
      }
      console.info(
        `Service "${serviceName}" has no value indicating whether it should be mocked.`
      );
    }

    // If the service is not listed or required to be mocked, use a normal instance.
    // Because Typescript won't permit constructing for abstract classes,
    // we cast the type to be constructable.
    return new (this as unknown as Constructor<T>)();
  }
}
