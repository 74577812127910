/* eslint-disable no-console -- The logger mock uses `console.log` to log messages. */
import { formatErrorMessage } from '@/utils/error-utils';
import { mocked } from '@/configs';
import type { Nullable } from '@/type-utils';
import chalk from 'chalk';
import { terminalSize } from '@/utils/node-utils/terminal-utils';
import interceptStdOut from 'intercept-stdout';
import readline from 'readline';
import path from 'path';
import sourceMapSupport from 'source-map-support';
import { Constructor } from '@/type-utils';
import Service from '../../Service';
import MockService, { MockState, ServiceMock } from '../MockService';
import { LogLevel } from './LogLevel';
import type { LoggerService, Loggable } from './LoggerService';
import { EnvironmentService } from '../EnvironmentService';

/** The initial state value for the Logger mock's mocked state. */
const initialState = {};

/**
 * A function to "prettify" logging output in Node environments. `undefined` by default
 * because we need to assess that we're actually in a Node environment first in order to
 * tree-shake out any Node code for the client-side.
 */
let nodeLoggerMiddleware: Nullable<
  (msg: string, logLevel: LogLevel, callsites?: Array<string>) => string
>;

// Checks to see if we're in a Node environment. Not using the `EnvironmentService`
// here to avoid circular dependencies since this service is even lower level than
// the `EnvironmentService`. Further more this is inlined to better enable dead code
// elimination in the client-side code such that this block is tree-shaken out.
//
// Besides that, we'll also check whether we're running remotely. If we are, regardless
// of whether we're on the server-side, we'll not intercept the console output. As it
// may have unforeseen consequences within the remote environment - Vercel. To this end,
// we ensure that the `VERCEL` environment variable is not set.
if (typeof window === 'undefined' && !process.env.VERCEL) {
  /**
   * A function to "prettify" logging output in Node environments. It will colorize the
   * message according to the log level and wrap the message in a header and footer if the
   * message is a "block" (For example: it contains a newline).
   * @param msg - The log message to format.
   * @param logLevel - The log level of the message to log.
   * @param callsites - The callstack of the function that called the logger.
   * @returns The formatted message.
   */
  nodeLoggerMiddleware = (
    msg: string,
    logLevel: LogLevel,
    callsites?: Array<string>
  ): string => {
    // If the message includes a newline, we'll consider it to be a "block" and we'll
    // "wrap" it in a header and footer to separate it from other log messages visually.
    if (msg.includes('\n')) {
      // `process.stdout.columns` isn't always available, so we'll use the `term-size`
      // package to ascertain the console width.
      const consoleWidth = terminalSize().columns;
      const headLineText = `[${logLevel.toLocaleUpperCase()}] `;

      // Create header text in the form of `[LOG LEVEL] =====`. Where the postfix "="s
      // fill the rest of the console width.
      const headLine = `${headLineText.padEnd(
        consoleWidth - headLineText.length,
        '='
      )}`;
      const footer = '='.repeat(consoleWidth - 1);

      msg = `${headLine}\n${msg}\n${footer}`;
    }
    // Otherwise, prefix the message with the log level. Like so: `[INFO] Message`.
    else {
      msg = `[${logLevel.toLocaleUpperCase()}] ${msg}`;
    }

    // Determine how to colorize the message depending on the log level.
    switch (logLevel) {
      case LogLevel.Error:
        msg = chalk.red(msg);
        break;
      case LogLevel.Warn:
        msg = chalk.yellow(msg);
        break;
      case LogLevel.Info:
        msg = chalk.blue(msg);
        break;
      case LogLevel.Debug:
        msg = chalk.gray(msg);
        break;
      default:
        // If the log level is unknown, just return the message as-is. Albeit this is
        // probably representation of a bug, we don't want to throw an error here as
        // logging is a critical part of the application and may itself be the result of
        // an error.
        break;
    }

    let callerInfo: Nullable<string> = null;

    // If we have callstack information, try to find the actual caller of the logger.
    if (callsites) {
      // Find the first callstack entry that isn't from the logger itself or the mock
      // service, etc.
      const caller = callsites.find((callsite) => {
        return (
          callsite &&
          !callsite.includes('LoggerService') &&
          !callsite.includes('MockService')
        );
      });

      // `getPath` gets the path of the file that called the logger. It then uses the
      // regex `webpack-internal:\/\/\/.*?\.(\/.*?):` to extract the path of the file and
      // convert it to an absolute path to the file.
      const getPath = (callsite: string): string => {
        // First get the original path
        const pathStr = /webpack-internal:\/\/\/.*?\.(\/.*?):/.exec(
          callsite
        )?.[1];

        // Then, get the application directory path.
        const appDir = process.cwd();

        // Then, if we have a path, convert it to an absolute path.
        const absPath = pathStr ? path.join(appDir, pathStr) : '';

        // Then, update the path in the original callsite string.
        return callsite.replace(
          /webpack-internal:\/\/\/.*?\.(\/.*?):/,
          absPath + ':'
        );
      };

      if (caller) {
        callerInfo = chalk.gray.dim(
          `(Logged from: ${getPath(caller.replace('at', '').trim())})`
        );
      }
    }

    // Clear the current line in the console. This is necessary because the message may be
    // logged while the console is in the middle of a "realtime" update. For example, if
    // the message is logged while a process is outputting a progress bar to the console.
    readline.clearLine(process.stdout, 0);

    // Output the formatted message to the console. Ensure there's always a line break
    // between messages. Sometimes, when a process is updating the buffer in "realtime"
    // the message might otherwise be printed on the same line as the previous message.
    // If there was any caller info, append it to the message.
    return `\n${msg}${callerInfo ? `\n${callerInfo}` : ''}`;
  };

  // Install source map support so that we can get the original source code line numbers.
  try {
    sourceMapSupport.install({
      hookRequire: true
    });
  } catch (error) {
    console.error(
      new Error('Calling `sourceMapSupport.install` failed.', { cause: error })
    );
  }

  // Additionally, in Node environments, let's intercept stderr and logs those as errors.
  // Uncaught errors, for example, will be logged to stderr and we want to make sure those
  // are logged as errors both formatted and colorized.
  interceptStdOut(
    (msg: string) => {
      // The type of this callback as defined in `interceptStdOut` is incorrect and a
      // `Buffer` can come through rather than just `string`. In this event, we need to
      // first check if the message is a string. If it is a string, we'll check if it is
      // empty. If the message is empty, just return it as-is.
      if (typeof msg !== 'string' || msg === '' || msg?.trim() === '') {
        return msg;
      }

      // Remove any leading or trailing whitespace including newlines.
      const trimmedMsg = msg?.trim?.() ?? '';

      // Heuristically try to determine if the message is an error. If it is, log it as an
      // error with our formatting. The following checks try to be as conservative as possible.
      if (
        // Check for "[", because "[INFO]", "[DEBUG]", and Next's loader "[=== ]" are
        // logged to stdout, we never want to treat these like possible errors.
        // Also check for "warn  -" and "info  -" because NextJS logs warnings and info to
        // stdout. We check for these first to avoid false positives. The condition will
        // short circuit if the message starts with one of these strings, thus sparing us
        // the more expensive regex check.
        !trimmedMsg.startsWith('[') &&
        !trimmedMsg.startsWith('warn  -') &&
        !trimmedMsg.startsWith('info  -') &&
        // Check for "Error:" or "error occurred". "Error:" should capture thrown errors
        // like "Error: Something went wrong" or "ResourceError: Could not find resource".
        // "error occurred" should capture errors output by NextJS like "Build error
        // occurred" or "Error occurred prerendering page".
        /^.*?(Error:|error occurred)/i.test(trimmedMsg) &&
        // Finally, just to be sure, check for "at " which should be present in any stack trace.
        trimmedMsg.includes('at ')
      ) {
        return nodeLoggerMiddleware!(trimmedMsg, LogLevel.Error);
      }

      // Otherwise, just return the message as-is.
      return msg;
    },
    (msg: string) => {
      // The type of this callback as defined in `interceptStdOut` is incorrect and a
      // `Buffer` can come through rather than just `string`. In this event, we need to
      // first check if the message is a string. If it is a string, we'll check if it is
      // empty. If the message is empty, just return it as-is.
      if (typeof msg !== 'string' || msg === '' || msg?.trim() === '') {
        return msg;
      }

      // Remove any leading or trailing whitespace including newlines.
      const trimmedMsg = msg?.trim?.() ?? '';

      // If the message does not start with "[WARN]" and contains "Error:" and "at ",
      // we'll consider it to be an error and log it as such. We ignore "[WARN]" because
      // because warnings are logged to `stderr` as well and we'd otherwise end up logging
      // our own warnings as errors.
      if (
        // Ignore "[WARN]" because warnings are logged to `stderr` as well and we'd
        // otherwise end up logging our own warnings as errors.
        !trimmedMsg.startsWith('[WARN]') &&
        // Check for "Error:" or "error occurred". "Error:" should capture thrown errors
        // like "Error: Something went wrong" or "ResourceError: Could not find resource".
        // "error occurred" should capture errors output by NextJS like "Build error
        // occurred" or "Error occurred prerendering page".
        /^.*?(Error:|error occurred)/i.test(trimmedMsg) &&
        // Finally, just to be sure, check for "at " which should be present in any stack trace.
        trimmedMsg.includes('at ')
      ) {
        return nodeLoggerMiddleware!(trimmedMsg, LogLevel.Error);
      }

      // Otherwise, just return the message as-is.
      return msg;
    }
  );
}
// If we're not in a Node environment, we'll just return the message as-is.
else {
  /**
   * A function to "prettify" logging output in Node environments. In this instance the
   * function will purely return the message as-is and let the browser handle the formatting.
   * @param msg - The log message to format.
   * @param logLevel - The log level of the message to log.
   * @returns The formatted message.
   */
  nodeLoggerMiddleware = (msg: string, logLevel: LogLevel): string => msg;
}

/**
 * Mock implementation of the LoggerService class.
 */
export default class LoggerServiceMock extends ServiceMock<LoggerService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): LoggerService {
    return MockService.getMockOf(this.service) as unknown as LoggerService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<LoggerService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(service: Constructor<LoggerService>): void {
    const mockEnabled: typeof mocked.LoggerService = mocked.LoggerService;

    const log = (message: Loggable, level?: LogLevel): void => {
      // In a Node environment, we'll also log the caller from the call stack.
      // This is useful for debugging, but we don't want to do this in the browser
      // because it the browser's console will already show the caller. Additionally, we
      // don't want to do this in when the NODE_ENV is set to production because it's a
      // performance hit and because during build time, there will be no source maps for
      // the loggers to use.
      const callsites =
        typeof window !== 'undefined' || process.env.NODE_ENV === 'production'
          ? undefined
          : new Error().stack?.split('\n').slice(1);

      console[level ?? 'log'](
        /* eslint-disable @typescript-eslint/no-non-null-assertion --
         * `nodeLoggerMiddleware` is assigned in a if/else block above, so it will never
         * be undefined.
         */
        nodeLoggerMiddleware!(
          message instanceof Error
            ? formatErrorMessage(
                message,
                true,
                // Use the compact stack trace when we're building the application to cut
                // down on the size of the build logs.
                EnvironmentService.isBuilding
              )
            : message,
          level ?? LogLevel.Info,
          callsites
        )
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
      );
    };

    MockService.mockService(
      mockEnabled,
      service,
      {
        error: (message: Loggable): void => {
          log(message, LogLevel.Error);
        },
        warn: (message: Loggable): void => {
          log(message, LogLevel.Warn);
        },
        info: (message: Loggable): void => {
          log(message, LogLevel.Info);
        },
        debug: (message: Loggable): void => {
          log(message, LogLevel.Debug);
        }
      },
      {},
      this.state
    );
  }
}
