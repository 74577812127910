import config from './config';
import { ECOMM_ENABLED } from './env/public';

const ecommerce = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Config used by e-commerce enabled middleware. Dictates whether or not e-commerce
       * functionality such as account, checkout, cart, and returns are enabled.
       */
      ecommerceEnabled: ECOMM_ENABLED,
      /**
       * Used for filtering out links in the footer based on their "keys" as defined in
       * the configuration.
       */
      ecommerceKeys: ['orders', 'cart', 'checkout', 'account']
    }
  },
  'AHNU-US': {
    default: {
      ecommerceKeys: ['orders', 'cart', 'checkout', 'account', 'help-center']
    }
  }
});

export default ecommerce;
