/**
 * Enumerates valid US Territories as
 * [USPS Postal Codes](https://pe.usps.com/text/pub28/28apb.htm).
 */
export enum USTerritory {
  /**
   * Represents **American Samoa** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  AS = 'AS',

  /**
   * Represents **Guam** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  GU = 'GU',

  /**
   * Represents **Federated States of Micronesia** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  FM = 'FM',

  /**
   * Represents **Marshall Islands** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  MH = 'MH',

  /**
   * Represents **Northern Mariana Islands** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  MP = 'MP',

  /**
   * Represents **Palau** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  PW = 'PW',

  /**
   * Represents **Puerto Rico** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  PR = 'PR',

  /**
   * Represents **Virgin Islands** as a
   * [USPS Postal Code](https://pe.usps.com/text/pub28/28apb.htm).
   */
  VI = 'VI'
}
