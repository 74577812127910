import { CardNetwork, TrueCardNetwork } from '@/constructs/CardNetwork';

import config from './config';

const cards = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * The allowed cards list. If a card is either missing on the list or
       * explicitly set to `false` it will be treated as an unsupported
       * card and throw an error in checkout.
       */
      allowedCards: {
        [CardNetwork.Visa]: true,
        [CardNetwork.MasterCard]: true,
        [CardNetwork.AmericanExpress]: true,
        [CardNetwork.UnionPay]: true,
        [CardNetwork.JCB]: true,
        [CardNetwork.DinersClubInternational]: false,
        [CardNetwork.DinersClubUSandCanada]: false,
        [CardNetwork.DiscoverCard]: true,
        [CardNetwork.Maestro]: false,
        [CardNetwork.SoloDebit]: false,
        [CardNetwork.SwitchDebit]: false,
        [CardNetwork.VisaElectron]: false,
        [CardNetwork.enRoute]: false,
        [CardNetwork.Klarna]: false,
        [CardNetwork.Invalid]: false,
        [CardNetwork.Unknown]: false
      } satisfies Record<CardNetwork, boolean>,

      /**
       * The ranges credit card networks may use for their Issuer Identification
       * Numbers (IINs), a.k.a PAN or card number.
       *
       * These ranges are used to identify the network of a given credit card by
       * checking if its first digits are within the range of a configured
       * network.
       *
       * This method also works if the credit card number has only been
       * partially entered.
       *
       * @see [Credit Card IIN Ranges & Spacing Patterns | Baymard Institute](https://baymard.com/checkout-usability/credit-card-patterns)
       * @see [What Is an Issuer Identification Number (IIN)? | The Balance](https://www.thebalancemoney.com/what-is-an-issuer-identification-number-iin-5208750)
       */
      iinRanges: {
        [CardNetwork.VisaElectron]: '4026,417500,4405,4508,4844,4913,4917',
        [CardNetwork.AmericanExpress]: '34,37',
        [CardNetwork.UnionPay]: '62,88',
        [CardNetwork.MasterCard]: '51-55,222100-272099',
        [CardNetwork.DinersClubInternational]: '300-305,309,36,38-39',
        [CardNetwork.DinersClubUSandCanada]: '54,55',
        [CardNetwork.DiscoverCard]: '6011,622126-622925,644-649,65',
        [CardNetwork.JCB]: '3528-3589',
        [CardNetwork.SoloDebit]: '6334,6767',
        [CardNetwork.SwitchDebit]:
        '4903,4905,4911,4936,564182,633110,6333,6759',
        [CardNetwork.Maestro]: '500000-509999,560000-589999,600000-699999',

        // Leave Visa last since it is the broadest range and may obscure some
        // of the ranges above.
        [CardNetwork.Visa]: '4',
      } satisfies Record<TrueCardNetwork, string>,

      /**
       * Specifies the length of a valid security code per network.
       */
      securityCodeLengths: {
        /** Default security code length for unspecified networks. */
        defaultLength: 3,

        [CardNetwork.AmericanExpress]: 4
      },

      /**
       * A per-network list of spacing patterns to use for card numbers. These
       * reflect the way the digits and spaces are printed on the physical
       * credit cards, and we use the same patterns to mask credit card numbers
       * on entry for better user experience.
       *
       * For each network an object must be provided, where the keys must be
       * the amount of digits in the card, and the values must be a
       * comma-separated list of numbers representing the size of each digit
       * group.
       *
       * @example `Visa spacing pattern: '4,4,4,4' -> Credit card: 1111 1111 1111 1111`
       * @example `Amex spacing pattern: '4,6,5' -> Credit card: 1111 111111 11111`
       *
       * @see [Credit Card IIN Ranges & Spacing Patterns | Baymard Institute](https://baymard.com/checkout-usability/credit-card-patterns)
       */
      spacingPatterns: {
        [CardNetwork.Visa]: {
          16: '4,4,4,4'
        },
        [CardNetwork.VisaElectron]: {
          16: '4,4,4,4'
        },
        [CardNetwork.AmericanExpress]: {
          15: '4,6,5'
        },

        [CardNetwork.UnionPay]: {
          16: '4,4,4,4',
          19: '6,13'
        },
        [CardNetwork.MasterCard]: {
          16: '4,4,4,4'
        },
        [CardNetwork.Maestro]: {
          13: '4,4,5',
          15: '4,6,5',
          16: '4,4,4,4',
          19: '4,4,4,4,3'
        },
        [CardNetwork.DinersClubInternational]: {
          14: '4,6,4'
        },
        [CardNetwork.DinersClubUSandCanada]: {
          16: '4,4,4,4'
        },
        [CardNetwork.DiscoverCard]: {
          16: '4,4,4,4'
        },
        [CardNetwork.JCB]: {
          16: '4,4,4,4'
        },
        [CardNetwork.SoloDebit]: {
          16: '4,4,4,4',
          19: '4,4,4,4,3'
        },
        [CardNetwork.SwitchDebit]: {
          16: '4,4,4,4'
        }
      } satisfies Record<TrueCardNetwork, Record<number, string>>
    }
  }
});

export default cards;
