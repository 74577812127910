import config from './config';

const user = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * Specifies the relative location that the `logout` method should redirect to. We
       * redirect when calling the `logout` method because we want to ensure that the
       * request cannot continue after the user has logged out. This is to prevent any
       * kind of state management issues that could occur if other services were relying
       * on previously obtained user data, that would no longer be valid after the user
       * has logged out.
       */
      logoutRedirectLocation: '/', // Redirect to homepage

      /**
       * Specifies the relative location that the user should be redirected to on succesful
       * login. As with the logout redirect, this is to prevent any kind of state management
       * issues that could occur if other services were working under the assumption that
       * no user is logged in at the moment.
       */
      loginRedirectLocation: '/account',

      /**
       * Specifies the relative location that the user should be redirected to on succesful
       * sign up. As with the logout redirect, this is to prevent any kind of state management
       * issues that could occur if other services were working under the assumption that
       * no user is logged in at the moment.
       */
      signUpRedirectLocation: '/account',

      /**
       * Specifies the relative location that the user should be redirected to on successful
       * login from the checkout flow. As with the logout redirect, this is to prevent any kind
       * of state management issues that could occur if other services were working under the
       * assumption that no user is logged in at the moment.
       */
      checkoutRedirectLocation: '/checkout',

      /**
       * Specifies the relative location that the user should be redirected to in order
       * to log in. This redirect would happen whenever the user tries to access a page
       * that requires them to be logged in.
       */
      loginURL: '/login',

      /**
       * Specifies the relative location of the account page.
       * The user will be redirected to this location if they try to access a page
       * that requires them to be anonymous/guest users (for instance, the login page).
       */
      accountURL: '/account'
    }
  }
});

export default user;
