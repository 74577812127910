import type { ConfigSchema } from './types/ConfigSchema';

/**
 * Used to ensure that a config object literal's type can be inferred as a config.
 *
 * @param config - The input object literal to infer the shape of the config from.
 * @returns The config as a `ConfigSchema<T>` where `T` is inferred from the
 * input config object literal.
 *
 * @todo Investigate adding type-checking to enforce that
 * overrides match their default shape.
 */
export default <const T extends ConfigSchema<T>>(config: T): ConfigSchema<T> =>
  config;
