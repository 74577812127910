import config from '../config';

import { UPS_API_PASSWORD } from '../env/server';

/** Configuration for the UPS integration. */
const ups = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * The base url for handling UPS requests.
       * Nonprod: https://wwwcie.ups.com/.
       */
      baseURL: 'https://onlinetools.ups.com/',
      /** The UPS dropoff location URL. */
      dropoffUrl: 'https://www.ups.com/dropoff',
      /** The UPS pickup location URL. */
      pickupUrl:
        'https://www.ups.com/us/en/business-solutions/pickup-dropoff-options/ups-on-call-pickup.page',
      /** The endpoint for the auth call. */
      authEndpoint: '/security/v1/oauth/token',
      /** The endpoing for the shipping calls. */
      shipEndpoint: '/api/shipments/v2403/ship',
      /** The recovery endpoint used for reprint. */
      recoverEndpoint: '/api/labels/v2403/recovery',
      /**
       * The UPS merchant id used to retrieve auth.
       */
      merchantID: 'X04A17',
      /**
       * The UPS authorization key used to retrieve auth, it is a <username>:<password> base64 encoded.
       * @see https://developer.ups.com/api/reference?loc=en_US#tag/OAuth-Client-Credentials
       */
      username: 'km40Tuba0mz9TNhz9wqI67YV51hkRCiCozQDAutU1FBTvrwg',
      /** The password used with the username to create the auth call key. */
      password: UPS_API_PASSWORD as string,
      /** The context for the label creation. */
      context: {
        default: 'deca-testing',
        prod: 'deca'
      },
      /** The length of the uuid for UPS calls. */
      transactionIDLength: 32,
      /** The max length of the reference number in order to be turned into a barcode. */
      referenceNumberLength: 14,
      /**
       * Values used to setup the configuration of the request body.
       * @see https://developer.ups.com/api/reference?loc=en_US#operation/Shipment
       */
      config: {
        /** Whether to validate the address, can be 'validate' or 'nonvalidate'. */
        validate: 'validate',

        /**
         * This is the type of return service. Value '9' means UPS Print Return Label (PRL).
         * There are a number of other values like '2' for UPS Print and Mail (PNM).
         */
        returnServiceCode: '9',
        /**
         * This is the type of service that the shipment will use. Value '03' means UPS Ground.
         * Other values include '01' for UPS Next Day Air, '02' for UPS 2nd Day Air, etc.
         */
        serviceCode: '03',
        /** The description of the shipping service. */
        serviceDescription: 'Ground',
        /**
         * The charge type of the shipment. '01' means transportation. Other values are used depending
         * on the country of origin and destination.
         */
        shipmentChargeType: '01',

        /** A description of the package. */
        packageDescription: '',
        /** The type of packaging. '02' means customer supplied packaging. */
        packageCode: '02',
        /** The unit of weight. */
        weightUnit: 'LBS',
        /** The description of the unit of weight. */
        weightDescription: 'Pounds',
        /** The weight in a given unit. */
        weight: '4',
        /**
         * The bar code indicator just sending a blank string causes this to appear.
         * From the docs listed above: "This is an empty tag, any value inside is ignored.".
         */
        barCodeIndicator: '',
        /** The format of the base64 encoded image. */
        labelImageFormat: 'GIF',
        /** User agent for the formatted image. */
        userAgent: 'Mozilla/4.5'
      }
    }
  }
});

export default ups;
