import config from './config';

/** Ths sitemap config holds configuration specific to the `SiteMapService`. */
const sitemap = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      // The environement string that is used to distinguish environments for the sitemap.
      environment: { default: 'beta', prod: 'prod' },
      // The brand designation, should reflect the sitemap brand.
      brand: undefined as unknown as string
    }
  },
  'AHNU-US': {
    default: {
      brand: 'ahnu'
    }
  },
  'SANUK-US': {
    default: {
      brand: 'sanuk'
    }
  }
});

export default sitemap;
