import { JSONValue } from '@/type-utils';

/**
 * This allows the mock service to maintain a service state for the
 * purposes of mocking. This state can then be manipulated allowing for
 * flexible mocking.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- For backwards compatibility.
export class MockState<T extends Record<never, JSONValue> = any> {
  // The stored initial state of the mocked service.
  private initialState: T;
  // The current state of the mocked service.
  private currentState: T;

  /**
   * The constructor sets the initial state based on a passed object literal value.
   * @param initialState - Object literal with initial values.
   */
  public constructor(initialState: T) {
    this.initialState = initialState;
    this.currentState = initialState;
  }

  /**
   * Sets a value on the current state object.
   * @param key - The key that you want to change on the state.
   * @param value - The new value.
   */
  public setState<K extends keyof T>(key: K, value: T[K]): void {
    this.currentState[key] = value;
  }

  /**
   * Gets the whole immutable current state object for reading.
   * @returns - The current state as is.
   */
  public getState(): Readonly<T> {
    return this.currentState;
  }

  /**
   * Saves a "snapshot" of the current state to the initial state.
   */
  public saveState(): void {
    this.initialState = this.currentState;
  }

  /**
   * Sets the current state to what is stored in the initial state.
   */
  public resetState(): void {
    this.currentState = this.initialState;
  }
}
