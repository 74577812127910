import config from './config';
import { ECOMM_ENABLED } from './env/public';

const environment = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * The base URL for getting a url that can get past any sort of
       * domain whitelisting, eg: forgot password.
       */
      baseURL: {
        default: 'https://development.sanuk.com',
        qa: 'https://qa.sanuk.com',
        prod: 'https://sanuk.com'
      }
    }
  },
  'AHNU-US': {
    default: {
      baseURL: {
        default: 'https://development.ahnu.co',
        qa: 'https://qa.ahnu.co',
        prod: 'https://ahnu.co'
      }
    }
  },
  'TEVA-US': {
    default: {
      baseURL: {
        default: 'https://development.teva.com',
        qa: 'https://qa.teva.com',
        prod: 'https://teva.com'
      }
    }
  },
  'UGG-COLLAB-US': {
    default: {
      baseURL: {
        default: 'https://development.ugg-collab.com',
        qa: 'https://qa.ugg-collab.com',
        prod: 'https://ugg-collab.com'
      }
    }
  },
  'UGG-US': {
    default: {
      baseURL: {
        default: 'https://development.ugg.com',
        qa: 'https://qa.ugg.com',
        prod: 'https://ugg.com'
      }
    }
  },
  'KOOLABURRA-US': {
    default: {
      baseURL: {
        default: 'https://development.koolaburra.com',
        qa: 'https://qa.koolaburra.com',
        prod: 'https://koolaburra.com'
      }
    }
  },
  'HOKA-US': {
    default: {
      baseURL: {
        default: 'https://development.hoka.com',
        qa: 'https://qa.hoka.com',
        prod: 'https://hoka.com'
      }
    }
  }
});

export default environment;
