import { EASYPOST_API_KEY } from '../env/server';
import config from '../config';

const easypost = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      baseURL: 'https://api.easypost.com/v2',
      apiKey: EASYPOST_API_KEY as string
    }
  }
});

export default easypost;
