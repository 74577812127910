/**
 * Enumerates the configured media sizes.
 */
enum ConfiguredMediaSize {
  ExtraSmall,
  Small,
  PDPSliderThumbLarge,
  PDPSliderThumbLarge2x,
  LifestyleThumbLarge,
  LifestyleThumbLarge2x,
  LifestyleDISSmall,
  LifestyleDISLarge,
  PDPSliderSmall,
  PDPSliderLarge,
  WishlistTitle
}

export default ConfiguredMediaSize;
