import config from './config';

const tagManagement = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /**
       * An on off switch for GTM, will not load the GTM scripts if this is
       * disabled.
       */
      enableGTM: true,
      /**
       * The tagId is placed within the block script as a way of identifying
       * the container. This would have to be changed if we changed our GTM container
       * on different sites or locales.
       * @see https://support.google.com/tagmanager/answer/6103696?hl=en
       */
      tagId: undefined // Overridden by site and locale configs.
    }
  },
  'SANUK-US': {
    default: {
      tagId: 'GTM-PL8QZVP'
    }
  },
  'AHNU-US': {
    default: {
      tagId: 'GTM-NMT2J4SS'
    }
  },
  'TEVA-US': {
    default: {
      tagId: 'GTM-P27VQGXS'
    }
  },
  'UGG-COLLAB-US': {
    default: {
      // TODO: This is Teva's tagId - Update tagId when a new GTM container is created for UGG-COLLAB.
      tagId: 'GTM-P27VQGXS'
    }
  },
  'UGG-US': {
    default: {
      // TODO: This is Teva's tagId - Update this tagId when a new GTM container is created for UGG.
      tagId: 'GTM-P27VQGXS'
    }
  },
  'HOKA-US': {
    default: {
      // TODO: This is Teva's tagId - Update this tagId when a new GTM container is created for HOKA.
      tagId: 'GTM-P27VQGXS'
    }
  },
  'KOOLABURRA-US': {
    default: {
      tagId: 'GTM-P27VQGXS'
    }
  }
});

export default tagManagement;
